import React, { useEffect, useState } from 'react';
import {
  Box,
  TextField,
  Button,
  Typography,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Snackbar, Alert
} from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import CRMItemsPickerDialog from '../pick_item';
import ItemsTable from './items_table';
import CRMWarehousesDialog from '../pick_warehouse';
import { orangeColor } from '../../../css/common_sx';
import LoadingBackdrop from '../../../common/loading_backdrop';
import AddIcon from '@mui/icons-material/Add';
import axios from 'axios';
import ViewDocumentItemDetail from './view_document_item_detail';
import { DateTimePicker } from '@mui/x-date-pickers';
import { useSnackbar } from '../../../common/error_provider';
import { useDialogSettings } from '../../../common/session_storage';

export default function CRMCreateNewDocument({ open, onClose }) {
  const [showItemsDialog, setShowItemsDialog] = useState(false);
  const [showWarehousesDialog, setShowWarehousesDialog] = useState(false);
  const [crmDocument, setcrmDocument] = useState({
    deliveryFrom: null,
    deliveryTo: null,
    items: []
  });
  const [warehouseType, setWarehouseType] = useState(''); // Track which warehouse (From/To) is being selected
  const [documentTypes, setDocumentTypes] = useState([]); // To store document types from API
  const [selectedDocumentType, setSelectedDocumentType] = useState(''); // To store selected document type
  const [requireAmount, setRequireAmount] = useState(false); // To track if amount is required for the selected type
  const [allowSrcWarehouse, setAllowSrcWarehouse] = useState(false); // To track if source warehouse is allowed for the selected
  const [allowDestWarehouse, setAllowDestWarehouse] = useState(false); // To track if destination warehouse is allowed for the selected
  const [requiredBalance, setRequiredBalance] = useState(false); // To store the required balance for the selected document type
  const [saving, setSaving] = useState(false);
  const [showItemDetailDialog, setShowItemDetailDialog] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [allowAddNewItems, setAllowAddNewItems] = useState(false);
  const { showError } = useSnackbar();
  const { saveSetting, getNamespaceSettings } = useDialogSettings();

  useEffect(() => {
    if (!open) return;
    axios.get('/Office/CRM/Documents/types')
      .then((response) => {
        setDocumentTypes(response.data); // Assuming the data is an array of objects { id, document_name, require_amount }
        setSelectedDocumentType(response.data[0]?.id || ''); // Select the first document type by default
      })
      .catch((error) => {
        console.error('Error fetching document types:', error);
      });
    console.log('asd', getNamespaceSettings('crmDocument'));
    setcrmDocument({ deliveryFrom: null, deliveryTo: null, items: [] });
  }, [open]);


  const handleDocumentTypeChange = (e) => {
    setSelectedDocumentType(e.target.value);
  };

  useEffect(() => {
    saveSetting('crmDocument', 'work1', crmDocument);

  }, [crmDocument]);

  useEffect(() => {
    if (!open) return;
    const selectedType = documentTypes.find(type => type.id === selectedDocumentType)
    setRequireAmount(selectedType?.require_amount || false);
    setAllowSrcWarehouse(selectedType?.allow_src || false);
    setAllowDestWarehouse(selectedType?.allow_dst || false);

    setcrmDocument((prev) => ({
      deliveryFrom: null,
      deliveryTo: null,
      items: prev.items,
    }));
    setRequiredBalance(selectedType?.require_balance || false);
  }, [selectedDocumentType]);


  useEffect(() => {
    if (!open) return;
    let allowSt = false;
    if (allowSrcWarehouse && !allowDestWarehouse && crmDocument.deliveryFrom) {
      allowSt = true;
    }
    if (allowDestWarehouse && !allowSrcWarehouse && crmDocument.deliveryTo) {
      allowSt = true;
    }
    if (allowSrcWarehouse && allowDestWarehouse && crmDocument.deliveryFrom && crmDocument.deliveryTo) {
      allowSt = true;
    }
    setAllowAddNewItems(allowSt);
  }, [crmDocument, allowSrcWarehouse, allowDestWarehouse]);

  const handleAddItem = (item) => {
    if (item) {
      setcrmDocument((prev) => ({
        ...prev,
        items: [...prev.items, { ...item, quantity: 1, gross: '', net: '', vat: '', amount: '' }],
      }));
    }
    setShowItemsDialog(false);
  };

  const handleIncrementQuantity = (index) => {
    setcrmDocument((prev) => ({
      ...prev,
      items: prev.items.map((item, i) =>
        i === index ? { ...item, quantity: item.quantity + 1 } : item
      ),
    }));
  };

  const handleDecrementQuantity = (index) => {
    setcrmDocument((prev) => ({
      ...prev,
      items: prev.items.map((item, i) =>
        i === index && item.quantity > 1 ? { ...item, quantity: item.quantity - 1 } : item
      ),
    }));
  };

  const handleFieldChange = (index, field, value) => {
    setcrmDocument((prev) => ({
      ...prev,
      items: prev.items.map((item, i) => {
        if (i === index) {
          const updatedItem = { ...item, [field]: value };

          if (requireAmount) {
            const net = parseFloat(updatedItem.net || 0);
            const vat = parseFloat(updatedItem.vat || 0);
            const quantity = parseFloat(updatedItem.quantity || 0);

            updatedItem.gross = net + vat;
            updatedItem.total = updatedItem.gross * quantity;
          }

          return updatedItem;
        }
        return item;
      }),
    }));
  };

  const handleRemoveItem = (index) => {
    setcrmDocument((prev) => ({
      ...prev,
      items: prev.items.filter((_, i) => i !== index),
    }));
  };

  const handleWarehouseSelection = (warehouse) => {
    setcrmDocument((prev) => ({
      ...prev,
      [warehouseType]: warehouse,
    }));
    setShowWarehousesDialog(false);
  };



  const handleSave = async () => {
    // Ensure every item has valid amount-related fields if required
    setSaving(false);
    if (allowSrcWarehouse && !crmDocument.deliveryFrom) {
      alert('Please select a source warehouse.');
      return;
    }
    if (allowDestWarehouse && !crmDocument.deliveryTo) {
      alert('Please select a destination warehouse.');
    }
    if (requireAmount) {
      const invalidItems = items.some(item =>
        !item.gross || !item.net || !item.vat || item.quantity == 0
      );
      console.log('Invalid items:', invalidItems);
      if (invalidItems) {
        alert('Please enter all required fields (Gross, Net, VAT, and Quantity) for all items.');
        return;
      }

    }
    setSaving(true);
    let doc_items = [];
    for (const item of crmDocument.items) {
      if (allowSrcWarehouse) {
        doc_items.push({
          item_id: item.id,
          item_qty: -item.quantity,
          warehouse: crmDocument.deliveryFrom.id,
          physical_items: item.physical_items ?? [],
        });
      }
      if (allowDestWarehouse) {
        if (requireAmount) {
          doc_items.push({
            item_id: item.id,
            item_qty: item.quantity,
            warehouse: crmDocument.deliveryTo.id,
            unit_gross_value: item.gross,
            unit_net_value: item.net,
            unit_vat_value: item.vat,
            physical_items: item.physical_items ?? [],
          });
        }
        else {
          doc_items.push({
            item_id: item.id,
            item_qty: item.quantity,
            warehouse: crmDocument.deliveryTo.id,
            physical_items: item.physical_items ?? [],
          });
        }
      }
    }
    const document_id = await axios.post('/Office/CRM/Documents', {
      document_type_id: selectedDocumentType,
      source_warehouse: crmDocument.deliveryFrom !== null ? crmDocument.deliveryFrom.id : -1,
      destination_warehouse: crmDocument.deliveryTo !== null ? crmDocument.deliveryTo.id : -1,
    });
    axios.post(`/Office/CRM/DocumentItems/${document_id.data[0].id}?create_store_items=true`, doc_items)
      .then((response) => {
        if (response.status === 200) {
          axios.get(`/Office/CRM/Documents/${document_id.data[0].id}`)
            .then((response) => {
              onClose(response.data);
              setSaving(false);
            })
            .catch((error) => {
              showError('Failed to fetch document');
              setSaving(false);
            });
        }
      }).catch((error) => {
        showError('Failed to save document');
        setSaving(false);
      });
  };

  const openWarehouseDialog = (type) => {
    setWarehouseType(type);
    setShowWarehousesDialog(true);
  };

  const handleClose = () => {
    if (!saving) {
      onClose(null);
    } else {
      onClose(null);
    }
  };


  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle bgcolor={orangeColor}>
        Create New Document</DialogTitle>
      <DialogContent>
        <Grid container spacing={3} mt={2}>
          {/* Document Type Selection */}
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Document Type</InputLabel>
              <Select
                value={selectedDocumentType}
                onChange={handleDocumentTypeChange}
              >
                {documentTypes.map((type) => (
                  <MenuItem key={type.id} value={type.id}>
                    {type.document_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6} key={'deliveryFrom'}>
            <Box display="flex" alignItems="center" marginY={2}>
              <Box>
                <Typography variant="body1">
                  Delivery From:
                </Typography>
                <Typography variant="body2">
                  {crmDocument['deliveryFrom']?.name || 'No selection'}
                </Typography>
                <Typography variant="body2">
                  {crmDocument['deliveryFrom']?.full_address || ''}
                </Typography>
              </Box>
              <Button
                variant="contained"
                color="primary"
                disabled={!allowSrcWarehouse}
                onClick={() => openWarehouseDialog('deliveryFrom')}
                style={{ marginLeft: 10 }}
              >
                {'deliveryFrom' === 'deliveryFrom' ? 'Choose Warehouse' : 'Choose Client'}
              </Button>
            </Box>
          </Grid>
          <Grid item xs={6} key={'deliveryTo'}>
            <Box display="flex" alignItems="center" marginY={2}>
              <Box>
                <Typography variant="body1">
                  Delivery To:
                </Typography>
                <Typography variant="body2">
                  {crmDocument['deliveryTo']?.name || 'No selection'}
                </Typography>
                <Typography variant="body2">
                  {crmDocument['deliveryTo']?.full_address || ''}
                </Typography>
              </Box>
              <Button
                variant="contained"
                color="primary"
                disabled={!allowDestWarehouse}
                onClick={() => openWarehouseDialog('deliveryTo')}
                style={{ marginLeft: 10 }}
              >
                {'deliveryTo' === 'deliveryFrom' ? 'Choose Warehouse' : 'Choose Client'}
              </Button>
            </Box>
          </Grid>

          <Grid item xs={6}>
            <TextField
              label="Document Partner Reference"
              name="partner_reference"
              fullWidth
              variant="filled"
              InputProps={{ sx: { borderRadius: 1 } }}
            />
          </Grid>

          <Grid item xs={6}>
            <DateTimePicker
              label="Document Date"
              value={new Date()}
              fullWidth
              onChange={(newValue) => console.log(newValue)}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField label="Description" fullWidth multiline rows={4} variant="outlined" />
          </Grid>

          {/* Items table section */}
          <Grid item xs={12}>
            <Typography variant="h6">Items</Typography>
            <ItemsTable
              items={crmDocument.items}
              onIncrement={handleIncrementQuantity}
              onDecrement={handleDecrementQuantity}
              onFieldChange={handleFieldChange}
              onRemove={handleRemoveItem}
              requireAmount={requireAmount}
              onClickItemInfo={(item) => {
                setSelectedItem({ document: { deliveryFrom: crmDocument.deliveryFrom, deliveryTo: crmDocument.deliveryTo }, ...item });
                setShowItemDetailDialog(true);
              }}
            />
            <Button
              fullWidth
              variant="outlined"
              disabled={!allowAddNewItems}
              onClick={() => setShowItemsDialog(true)}
              startIcon={<AddIcon />}
              style={{ marginTop: '20px' }}
            >
              Add New Item
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => {
          onClose(null);
        }}
          sx={{ color: orangeColor, borderColor: orangeColor, borderRadius: 2 }}
          variant="outlined">Cancel</Button>
        <Button onClick={handleSave} variant="contained"
          color="primary"
          sx={{ marginRight: 2, bgcolor: orangeColor, color: '#fff', borderRadius: 2 }}>Save</Button>
      </DialogActions>
      <LoadingBackdrop open={saving} text={"Creating new document"} />

      <CRMItemsPickerDialog open={showItemsDialog} onClose={handleAddItem} onSelect={(item) => {
        if (item) {
          setcrmDocument((prev) => ({
            ...prev,
            items: [...prev.items, { ...item, quantity: 1, gross: '', net: '', vat: '', amount: '' }],
          }));
        }
      }} />
      <CRMWarehousesDialog open={showWarehousesDialog} onClose={handleWarehouseSelection} />
      <ViewDocumentItemDetail
        open={showItemDetailDialog}
        onClose={(updatedItem) => {
          console.log('Close Documet Item View:', updatedItem);
          if (updatedItem) {
            setcrmDocument((prev) => ({
              ...prev,
              items: prev.items.map((item) =>
                item.id === updatedItem.id ? { ...item, ...updatedItem } : item
              ),
            }));
          }
          setShowItemDetailDialog(false);

          //i want to update the physical items of the item
        }}
        item={selectedItem}
        allowEdit={true}
      />
    </Dialog>
  );
}

