import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Divider,
  Paper,
  TextField,
  Button,
  Grid,
  Avatar,
  Tabs,
  Tab,
  List,
  ListItem,
  ListItemText,
  IconButton,
} from "@mui/material";
import { orangeColor } from "../../../../css/common_sx";
import { Edit as EditIcon } from "@mui/icons-material";
import TextFieldDialog from "../../../../Dialogs/TextFieldDialog";
import NumberFieldDialog from "../../../../Dialogs/NumberFieldDialog";
import SelectFieldDialog from "../../../../Dialogs/SelectFieldDialog";
import DateFieldDialog from "../../../../Dialogs/DateFieldDialog";
import axios from "axios";
import { useSnackbar } from "../../../../common/error_provider";
import LoadingBackdrop from "../../../../common/loading_backdrop";
export default function WorkProjectViewHub({ args }) {
  const [tabValue, setTabValue] = useState(0);
  const [workProject, setWorkProject] = useState({});
  const [saveAble, setSaveAble] = useState(false);
  const [currentEditor, setCurrentEditor] = useState("");
  const [currentEditField, setCurrentEditField] = useState("");
  const [currentEditValue, setCurrentEditValue] = useState("");
  const [currentEditTitle, setCurrentEditTitle] = useState("");
  const { showError, showInfo } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    setLoading(true);
    setWorkProject({ project_id: args.project_id, tasks: [] });
    if ((args.new !== undefined) && (args.new === true)) {
      setWorkProject(prev => ({ ...prev, new: true, type_id: args.type_id, type_name: args.type_name }));
      fetchDefaultConsts(args.project_id);
    } else {
      setWorkProject((prev) => ({ ...prev, id: args.id, project_id: args.project_id }));
      fetchWorkProject(args.project_id, args.id);
    }
  }, []);

  const fetchDefaultConsts = async (project_id) => {
    try {
      axios.get(`/Office/Projects/WorkProjects/${project_id}/default_consts`)
        .then((response) => {
          setWorkProject((prev) => ({ ...prev, ...response.data }));
          setLoading(false);
        })
        .catch((error) => { });
    } catch (error) {
      console.error("Error fetching default consts:", error);
    }
  };

  const fetchWorkProject = async (project_id, id) => {
    try {
      axios.get(`/Office/Projects/WorkProjects/${project_id}/${id}`)
        .then((response) => {
          setWorkProject(prev => ({ ...prev, ...response.data }));
          setLoading(false);
        })
        .catch((error) => { });
    } catch (error) {
      console.error("Error fetching work project:", error);
    }

  };

  const getValue = (key) => {
    if (workProject[key] !== undefined) {
      return workProject[key];
    } else {
      return "N/A";
    }
  };

  const setValue = (key, value) => {
    setWorkProject({ ...workProject, [key]: value });
    setSaveAble(true);
  }

  const handleEditorSave = (value) => {
    console.log('Saving:', currentEditField, value);
    if (currentEditField === 'priority_id') {
      setValue(currentEditField, value.id);
      setValue('priority_label', value.label);
    }
    else if (currentEditField === 'status_id') {
      setValue(currentEditField, value.id);
      setValue('status_label', value.label);
    }
    else if (currentEditField === 'version_id') {
      setValue(currentEditField, value.id);
      setValue('version_label', value.label);
    }
    else if (currentEditField === 'expected_finish_date') {
      setValue(currentEditField, value.toISOString());
    }
    else {
      setValue(currentEditField, value);
    }
    setCurrentEditField("");
  };

  const handleEdit = (key) => {
    setCurrentEditField(key);
    setCurrentEditValue(getValue(key));
    if (key === 'package_name') {
      setCurrentEditTitle('Edit Package Name');
      setCurrentEditor('text');
    }
    if (key === 'ready_percent') {
      setCurrentEditTitle('Edit Progress');
      setCurrentEditor('number');
    }
    if (key === 'priority_id') {
      setCurrentEditTitle('Edit Priority');
      setCurrentEditor('select');
    }
    if (key === 'status_id') {
      setCurrentEditTitle('Edit Status');
      setCurrentEditor('select');
    }
    if (key === 'version_id') {
      setCurrentEditTitle('Edit Version');
      setCurrentEditor('select');
    }
    if (key === 'expected_finish_date') {
      setCurrentEditTitle('Edit Expected Finish Date');
      setCurrentEditor('date');
    }
  };

  const handleProjectSave = () => {
    if (workProject.new) {
      //Check type_id, status_id, priority_id, project_id
      for (const key of ['type_id', 'status_id', 'priority_id', 'project_id', 'package_name']) {
        if (workProject[key] === undefined) {
          alert(`Please set ${key}`);
          console.log('MIssing Key:', key);
          return;
        }
      }
    }
    axios.post(`/Office/Projects/WorkProjects/${workProject.project_id}`, workProject)
      .then((response) => {
        if (response.status === 200) {
          setSaveAble(false);
          setWorkProject({ ...workProject, ...response.data });
          showInfo('Project saved successfully');
        }
      })
      .catch((error) => {
        showError('Error saving project');
      });

  };

  const handleDescriptionChange = (e) => {
    const value = e.target.value;
    //setValue('description', value);
    extractTasksFromDescription(value);
  };

  const extractTasksFromDescription = (content) => {
    const lines = content.split("\n");
    const taskList = lines
      .filter(line => line.trim().startsWith("- ")) // Match lines starting with "- "
      .map((line, index) => ({
        id: index,
        task: line.replace("- ", "").trim(),
      }));
    console.log('Task List:', taskList, workProject);
    setWorkProject({ ...workProject, tasks: taskList, description: content });
  };


  return (
    <Box display="flex" flexDirection="column" width="100%" height="100%" bgcolor="#001F3F" color="#FFF" p={2}>
      {/* Header */}
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
        <Box>
          <Typography variant="h5" color="#FFD700">
            Work Project: {workProject.id !== undefined ? <>
              <span
                onClick={(event) => {
                  event.stopPropagation();
                }}
                style={{
                  color: 'blueviolet',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
              >
                {workProject.id}
              </span> {` Name: ${workProject.package_name ?? "N/A"}`} </> : 'Create Package'}
            <IconButton
              size="small"
              color="primary"
              onClick={() => handleEdit("package_name")}
              sx={{ marginLeft: 1, padding: 0 }}
            >
              <EditIcon />
            </IconButton>
          </Typography>
          <Typography color={orangeColor} variant="h6">
            Type: {workProject.type_name || "N/A"}
          </Typography>

        </Box>
        <Button disabled={!saveAble} onClick={handleProjectSave} variant="contained" sx={{ backgroundColor: "#FFD700", color: "#001F3F" }}>
          Save
        </Button>
      </Box>

      <Divider sx={{ bgcolor: orangeColor }} />

      {/* Main Content */}
      <Grid container spacing={2} mt={2}>
        {/* Left Section */}
        <Grid item xs={8}>
          <Paper elevation={3} sx={{ p: 2, bgcolor: "#002B5C", color: "#FFF" }}>
            <Typography variant="h6" mb={2}>
              Description
            </Typography>
            <TextField
              fullWidth
              multiline
              rows={5}
              variant="outlined"
              placeholder="Click to edit..."
              value={getValue('description')}
              onChange={handleDescriptionChange}
              sx={{ backgroundColor: "#FFF", borderRadius: 1 }}
            />

            <Typography variant="h6" mt={4} mb={2}>
              People
              <IconButton
                size="small"
                color="primary"
                onClick={() => handleEdit("people")}
                sx={{ marginLeft: 1, padding: 0 }}
              >
                <EditIcon />
              </IconButton>
            </Typography>
            <Box display="flex" flexDirection="row" overflow='autp'>
              {
                workProject.people !== undefined && (workProject.people || [])
                  .map((person, index) => (
                    <Box display="flex" alignItems="center" gap={2}>
                      <Avatar sx={{ bgcolor: "#FFD700" }}>RW</Avatar>
                      <Typography>{person.name}</Typography>
                    </Box>
                  ))
              }

            </Box>

            <Typography variant="h6" mt={4} mb={2}>
              Details
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography>Created At: {new Date(workProject.created_at || (new Date())).toLocaleString()}</Typography>
                <Typography>Created By: {workProject.created_by}</Typography>
                <Typography>Progress: {getValue('ready_percent')}%
                  <IconButton
                    size="small"
                    color="primary"
                    onClick={() => handleEdit("ready_percent")}
                    sx={{ marginLeft: 1, padding: 0 }}
                  >
                    <EditIcon />
                  </IconButton>
                </Typography>
                <Typography>Category: {getValue('categories')}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>Version: {getValue('version_label')}
                  <IconButton
                    size="small"
                    color="primary"
                    onClick={() => handleEdit("version_id")}
                    sx={{ marginLeft: 1, padding: 0 }}
                  >
                    <EditIcon />
                  </IconButton>
                </Typography>
                <Typography>Priority: {getValue('priority_label')}
                  <IconButton
                    size="small"
                    color="primary"
                    onClick={() => handleEdit("priority_id")}
                    sx={{ marginLeft: 1, padding: 0 }}
                  >
                    <EditIcon />
                  </IconButton>
                </Typography>
                <Typography>Status: {getValue('status_label')}
                  <IconButton
                    size="small"
                    color="primary"
                    onClick={() => handleEdit("status_id")}
                    sx={{ marginLeft: 1, padding: 0 }}
                  >
                    <EditIcon />
                  </IconButton>
                </Typography>
              </Grid>
            </Grid>

            <Typography variant="h6" mt={4} mb={2}>
              Estimates and Time
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography>Expected Finish date: {getValue('expected_finish_date') ? getValue('expected_finish_date').length > 0 ? new Date(getValue('expected_finish_date')).toLocaleDateString() : "" : ""}
                  <IconButton
                    size="small"
                    color="primary"
                    onClick={() => handleEdit("expected_finish_date")}
                    sx={{ marginLeft: 1, padding: 0 }}
                  >
                    <EditIcon />
                  </IconButton>
                </Typography>
                <Typography>Estimated Time: 8h</Typography>
                <Typography>Spent Time: 2h</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>Remaining Time: 6h</Typography>
              </Grid>
            </Grid>

            <Typography variant="h6" mt={4} mb={2}>
              Costs
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography>Overall Costs: {getValue('overall_cost')}</Typography>
                <Typography>Labor Costs: {getValue('labor_cost')}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>Unit Costs: -</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {/* Right Section */}
        <Grid item xs={4}>
          <Paper elevation={3} sx={{ p: 2, bgcolor: "#002B5C", color: "#FFF", mb: 5 }}>
            {/* Add Tasks */}
            <Typography variant="h6" mb={2}>
              Tasks
            </Typography>
            {workProject.tasks !== undefined && (workProject.tasks || []).map((task, index) => (
              <Box display="flex" alignItems="center" key={index} mb={1}>
                <Typography color='white'>{task.task}</Typography>
              </Box>
            ))}
          </Paper>

          <Paper elevation={3} sx={{ p: 2, bgcolor: "#002B5C", color: "#FFF" }}>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              textColor="inherit"
              indicatorColor="secondary"
              sx={{ marginBottom: 2 }}
            >
              <Tab label="Activity" />
              <Tab label="Files" />
              <Tab label="Relations" />
              <Tab label="Watchers (1)" />
            </Tabs>

            {tabValue === 0 && (
              <List>
                <ListItem>
                  <ListItemText
                    primary="May 31, 2022"
                    secondary={
                      <>
                        <Typography variant="body2" color="#FFD700">
                          Olaf Product updated on 05/31/2022 10:55 AM
                        </Typography>
                        <Typography variant="body2" color="white">
                          Priority changed from Normal to High. Progress (%) changed from 0 to 25. Estimated time set
                          to 8h. Remaining hours set to 6h.
                        </Typography>
                      </>
                    }
                  />
                </ListItem>
              </List>
            )}
            {tabValue === 1 && <Typography>No files available.</Typography>}
            {tabValue === 2 && <Typography>No relations found.</Typography>}
            {tabValue === 3 && <Typography>Watchers: 1</Typography>}
          </Paper>
        </Grid>
      </Grid>
      <TextFieldDialog
        open={currentEditor === 'text'}
        onClose={() => setCurrentEditor('')}
        title={currentEditTitle}
        onSave={handleEditorSave}
        defaultValue={currentEditValue}
      />
      <NumberFieldDialog
        open={currentEditor === 'number'}
        onClose={() => setCurrentEditor('')}
        title={currentEditTitle}
        onSave={handleEditorSave}
        defaultValue={currentEditValue}
        minValue={0}
        maxValue={100}
      />
      <DateFieldDialog
        open={currentEditor === 'date'}
        onClose={() => setCurrentEditor('')}
        title={currentEditTitle}
        onSave={handleEditorSave}
        defaultValue={currentEditValue}
      />
      <SelectFieldDialog
        open={currentEditor === 'select'}
        onClose={() => setCurrentEditor('')}
        title={currentEditTitle}
        onSave={handleEditorSave}
        defaultValue={currentEditValue}
        url={() => {
          switch (currentEditField) {
            case 'priority_id':
              return '/Office/Projects/Priorities';
            case 'status_id':
              return '/Office/Projects/Statuses';
            case 'version_id':
              return '/Office/Projects/Versions/' + workProject.project_id;
            default:
              return '';
          }
        }}

      />
      <LoadingBackdrop open={loading} text="Loading ..." />
    </Box>
  );
}

