import React, { useState, useEffect } from 'react';
import { Select, MenuItem, TextField } from '@mui/material';
import CommonDialog from './CommonDialog';
import axios from 'axios';

export default function TextFieldDialog({ open, onClose, onSave, title, defaultValue = '' }) {

  const [value, setValue] = useState('');

  useEffect(() => {
    if (open) setValue(defaultValue);
  }, [open]);

  return (
    <CommonDialog open={open} onClose={onClose} title={title} onSave={() => {
      if (onSave) onSave(value);
      if (onClose) onClose();
    }}>
      <TextField
        fullWidth
        label="Value"
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
    </CommonDialog>
  )
};
