import { Dialog, DialogTitle, DialogContent, DialogActions, Box } from "@mui/material";
import { orangeColor } from "../css/common_sx";
import { DialogSaveButton, DialogCancelButton } from "../common/DialogButtons";

export default function CommonDialog({ open, onClose, onSave, title, children }) {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle bgcolor={orangeColor}>{title}</DialogTitle>
      <DialogContent><Box p={2}>{children}</ Box></DialogContent>
      <DialogActions>
        <DialogSaveButton onClick={() => {
          if (onSave) {
            onSave();
          }
        }} />
        <DialogCancelButton onClick={() => {
          if (onClose) {
            onClose();
          }
        }} />
      </DialogActions>
    </Dialog>
  );
}
