import React, { useEffect, useState, useCallback, useMemo } from 'react';
import {
  Box, TextField, Button, IconButton, Snackbar, Alert, Skeleton, Typography,
  TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper, TablePagination, Stack
} from '@mui/material';
import {
  Add as AddIcon
} from '@mui/icons-material';
import axios from 'axios';
import CRMCreateNewDocument from './documents/create_new_document';
import { orangeColor, CommonSX } from '../../css/common_sx';
import { getStatusIcon } from './document_status_icon';
import { searchFunction } from '../../common/search_function';
import { debounce } from 'lodash';
import KeyIcon from '@mui/icons-material/Key';
import { TableGrid } from '../../common/TableGrid';
import CRMCreateNewPartner from './partners/create_new_partner';
export default function CRMParners() {
  const [partners, setPartners] = useState([]);
  const [globalSearch, setGlobalSearch] = useState('');
  const [loading, setLoading] = useState(true);
  const [openCreatePartner, setOpenCreatePartner] = useState(false);


  const fetchPartners = async () => {
    setLoading(true);
    try {
      axios.get('/Office/CRM/Partners')
        .then((response) => {
          setPartners(response.data);
          setLoading(false);
        }).catch((err) => {
          setLoading(false);
        });
    } catch (error) {
    }
  }
  const filteredPartners = useMemo(() => {
    return searchFunction(globalSearch, ['name', 'email', 'phone'], partners);
  }, [globalSearch, partners]);

  const handleSearchChange = useCallback(
    debounce((value) => {
      setGlobalSearch(value);
    }, 300), // wait 300ms before applying the search
    []
  );

  useEffect(() => {
    return () => {
      handleSearchChange.cancel();
    };
  }, [handleSearchChange]);

  useEffect(() => {
    fetchPartners();
  }, []);

  const columns = [
    'Name',
    'Email',
    'Phone',
    'Pending Orders Amount',
    'Pending Orders Count',
    'Total Orders (This year)',
    'Total Orders Amont (This year)',
    'Total Orders (All time)',
    'Total Orders Amount (All time)'];

  return (
    <Box display="flex" flexDirection="column" width="100%" height="100vh" flex={1} padding={2}>
      <Box display="flex" justifyContent="space-between" alignItems="center" padding={2} mb={2} borderRadius={2} boxShadow={3}
        bgcolor={orangeColor}>
        <TextField
          label={`Search `}
          onChange={(e) => handleSearchChange(e.target.value)}
          fullWidth
          sx={{ bgcolor: 'white', borderRadius: 1, mx: 2 }}
        />
        <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={() => setOpenCreatePartner(true)} >
          Create New Partner
        </Button>
      </Box>
      <TableGrid columns={columns} loading={loading} >
        {filteredPartners.map((partner) => (
          <TableRow key={partner.id} onClick={() => { }}>
            <TableCell >{partner.partner_name}</TableCell>
            <TableCell >{partner.partner_email}</TableCell>
            <TableCell >{partner.partner_phone}</TableCell>
            <TableCell >{partner.pending_orders_value}</TableCell>
            <TableCell >{partner.pending_orders_amount}</TableCell>
            <TableCell >{partner.orders_amount_this_year}</TableCell>
            <TableCell >{partner.orders_count_this_year}</TableCell>
            <TableCell >{partner.orders_amount_all_year}</TableCell>
            <TableCell >{partner.orders_count_all_year}</TableCell>
          </TableRow>
        ))}
      </TableGrid>
      <CRMCreateNewPartner
        open={openCreatePartner}
        onClose={() => setOpenCreatePartner(false)}
        onNew={(item) => {
          setPartners([item, ...partners]);
        }} />
    </Box>
  )
} 
