import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { searchFunction } from '../../../common/search_function';
import { TableGrid } from '../../../common/TableGrid';
import axios from 'axios';
import { debounce } from 'lodash';
import { Box, TextField, TableRow, TableCell, TablePagination } from "@mui/material";

export default function CRMWarehouseTableGridWithSearch({ onSelect }) {
  const [warehouses, setWarehouses] = useState([]);
  const [globalSearch, setGlobalSearch] = useState('');
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0); // Pagination page
  const [rowsPerPage, setRowsPerPage] = useState(30);


  const columns = [
    'ID',
    'Name',
    'Location',
    'Capacity',
    'Used Capacity',
    'Actions'
  ]

  const fetchWarehouses = async () => {
    setLoading(true);
    try {
      axios.get('/Office/CRM/Warehouses')
        .then((response) => {
          setWarehouses(response.data);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    } catch (error) {
    }
  };

  const handleSearchChange = useCallback(
    debounce((value) => {
      setGlobalSearch(value);
    }, 300), // wait 300ms before applying the search
    []
  );

  useEffect(() => {
    return () => {
      handleSearchChange.cancel();
    };
  }, [handleSearchChange]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredWarehouses = useMemo(() => {
    return searchFunction(globalSearch, ['id', 'name', 'location', 'capacity', 'used_capacity'], warehouses);
  }, [globalSearch, warehouses]);

  useEffect(() => {
    fetchWarehouses();
  }, []);

  const groupedWarehouses = filteredWarehouses.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).reduce((acc, warehouse) => {
    if (!acc[warehouse.owner_name]) {
      acc[warehouse.owner_name] = [];
    }
    acc[warehouse.owner_name].push(warehouse);
    return acc;
  }, {});


  return (
    <Box display="flex" flexDirection="column" width="100%" flex={1} padding={2}>
      <TextField
        label="Search"
        onChange={(e) => handleSearchChange(e.target.value)}
        fullWidth
        sx={{ bgcolor: 'white', borderRadius: 1, paddingBottom: 2 }}

      />
      <TableGrid columns={columns} loading={loading} rowsPerPage={rowsPerPage} enableOuterShell={false}>
        {
          Object.keys(groupedWarehouses).map((ownerName) => (
            <React.Fragment key={ownerName}>
              <TableRow>
                <TableCell colSpan={columns.length} align='center'>
                  <b>{ownerName}</b>
                </TableCell>
              </TableRow>
              {
                groupedWarehouses[ownerName].map((warehouse) => (
                  <TableRow hover key={warehouse.id} onClick={() => onSelect(warehouse)}>
                    <TableCell align='center'>{warehouse.id}</TableCell>
                    <TableCell align='center'>{warehouse.name}</TableCell>
                    <TableCell align='center'>{warehouse.full_address}</TableCell>
                    <TableCell align='center'>{warehouse.capacity}</TableCell>
                    <TableCell align='center'>{warehouse.used_capacity}</TableCell>
                    <TableCell align='center'>Actions</TableCell>
                  </TableRow>
                ))
              }
            </React.Fragment>
          ))
        }
      </TableGrid>
      <TablePagination
        rowsPerPageOptions={[10, 30, 50]}
        component="div"
        count={warehouses.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box >
  );
}
