import React, { useEffect, useState, useMemo, useCallback } from 'react';
import {
  Box,
  Typography,
  Button,
  IconButton,
  TextField,
  MenuItem, Menu,
  TablePagination,
  TableRow, TableCell,
  Select, Stack
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import InfoIcon from '@mui/icons-material/Info';
import ArticleIcon from '@mui/icons-material/Article';
import { ArrowCircleRightOutlined, DeleteRounded } from '@mui/icons-material';
import axios from 'axios';
import { useSnackbar } from '../../../common/error_provider';
import { orangeColor, CommonSX } from '../../../css/common_sx';
import { TableGrid } from '../../../common/TableGrid';
import { searchFunction } from '../../../common/search_function';
import { debounce } from 'lodash';
import { useMenu } from '../../../common/menu_navigator';

export default function Workpackages() {
  const [projects, setProjects] = useState([]);
  const { showError } = useSnackbar();
  const { navigateWithId } = useMenu();
  const [selectedProject, setSelectedProject] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setloading] = useState(false);
  const [globalSearch, setGlobalSearch] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [workPackageTypes, setWorkPackageTypes] = useState([]);

  useEffect(() => {
    // Fetch projects from endpoint
    axios.get('/Office/Projects/WorkPackageTypes')
      .then(response => {
        if (response.data.length > 0) {
          setWorkPackageTypes(response.data);
        }
      }).catch(error => {
        showError('Error fetching work package types');
      });
    axios.get('/Office/Projects')
      .then(response => {
        setProjects(response.data);
        if (response.data.length > 0) {
          setSelectedProject(response.data[0]);
        }
      })
      .catch(error => {
        showError('Error fetching projects');
      });
  }, []);

  useEffect(() => {
    if (selectedProject) {
      // Fetch tasks for selected project
      setloading(true);
      setTasks([]);
      axios.get(`/Office/Projects/WorkProjects/${selectedProject.id}`)
        .then(response => {
          setTasks(response.data);
          setloading(false);
        })
        .catch(error => {
          showError(`Error fetching tasks for ` + selectedProject.project_name);
        });
    }
  }, [selectedProject]);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleNewItemClicked = (type) => {
    handleMenuClose();
    navigateWithId(6002, { new: true, project_id: selectedProject.id, type_id: type.id, type_name: type.name });
  };

  const handleSearchChange = useCallback(
    debounce((value) => {
      setGlobalSearch(value);
    }, 300), // wait 300ms before applying the search
    []
  );

  useEffect(() => {
    return () => {
      handleSearchChange.cancel();
    };
  }, [handleSearchChange]);

  const filteredTasks = useMemo(() => {
    return searchFunction(globalSearch, ['package_name'], tasks)
  }, [globalSearch, tasks]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const columns = [
    'ID',
    'Name',
    'Status',
    'Type',
    'Priority',
    'Assigned Persons',
    'Expected Finish Date',
    'Actions'
  ];

  return (
    <Box display="flex" flexDirection="column" flex="1" width="100%" height="100%" pl={1} pr={1} pb={1}>
      <Box display="flex" justifyContent="space-between" alignItems="center" padding={2} mb={1} borderRadius={2} boxShadow={3}
        bgcolor={orangeColor}>
        <Select
          label="Project"
          value={selectedProject != null ? selectedProject.project_name ? selectedProject.id : '' : ''}
          onChange={(e) => setSelectedProject(projects.find(p => p.id === e.target.value))}
          sx={{ bgcolor: 'white', borderRadius: 1, }}>
          {projects.map(project => (
            <MenuItem key={project.id} value={project.id}>{project.project_name}</MenuItem>
          ))}
        </Select>
        <TextField
          fullWidth
          label="Search"
          sx={{ bgcolor: 'white', borderRadius: 1, mx: 1 }}
        />
        <Button variant="contained" color="primary" startIcon={<AddIcon />}
          onClick={handleMenuOpen} >
          Create
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          {workPackageTypes.map((type, index) => (
            <MenuItem key={index} onClick={() => handleNewItemClicked(type)} style={{ color: type.color }}>
              {(type.name || "N/A").toUpperCase()}
            </MenuItem>
          ))}
        </Menu>
      </Box>
      <TableGrid columns={columns} loading={loading}>
        {filteredTasks
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((task) => (
            <TableRow key={task.id}>
              <TableCell>{task.id}</TableCell>
              <TableCell>{task.package_name}</TableCell>
              <TableCell>{task.status_label}</TableCell>
              <TableCell sx={{ color: task.type_color || 'red' }}>{task.type_name}</TableCell>
              <TableCell>{task.priority_label}</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell>
                <Stack direction="row" spacing={1}>
                  <IconButton
                    size="small"
                    sx={{ padding: 0 }}
                    onClick={() => navigateWithId(6002, { id: task.id, project_id: selectedProject.id })}
                  >
                    <InfoIcon fontSize='small' />
                  </IconButton>
                </Stack>
              </TableCell>
            </TableRow>
          ))}
      </TableGrid>
      <TablePagination
        rowsPerPageOptions={[30, 50, 60]}
        component="div"
        sx={{ ...CommonSX, marginTop: '10px' }}
        count={tasks.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  )
}
