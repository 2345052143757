import React, { createContext, useContext, useState, useEffect } from 'react';

// Create Context
const DialogSettingsContext = createContext();

// Custom Hook for easier access
export const useDialogSettings = () => {
  return useContext(DialogSettingsContext);
};

// Provider Component
export const DialogSettingsProvider = ({ children }) => {
  const STORAGE_KEY = 'dialogSettings';

  // Load initial state from sessionStorage
  const [settings, setSettings] = useState(() => {
    const saved = sessionStorage.getItem(STORAGE_KEY);
    return saved ? JSON.parse(saved) : {};
  });

  // Update sessionStorage whenever settings change
  useEffect(() => {
    sessionStorage.setItem(STORAGE_KEY, JSON.stringify(settings));
  }, [settings]);

  /**
   * Save a setting under a namespace
   * @param {string} namespace - Key area (e.g., 'crm_new_document')
   * @param {string} key - Specific key within the namespace
   * @param {any} value - The value to save
   */
  const saveSetting = (namespace, key, value) => {
    setSettings((prev) => ({
      ...prev,
      [namespace]: {
        ...(prev[namespace] || {}),
        [key]: {
          value,
          timestamp: new Date().toISOString(),
        },
      },
    }));
  };

  /**
   * Retrieve a specific setting from a namespace
   * @param {string} namespace - Key area
   * @param {string} key - Specific key
   * @returns {object | null} - Value and timestamp
   */
  const getSetting = (namespace, key) => {
    return settings[namespace]?.[key] || null;
  };

  /**
   * Retrieve all settings within a namespace
   * @param {string} namespace - Key area
   * @returns {object} - All settings within the namespace
   */
  const getNamespaceSettings = (namespace) => {
    return settings[namespace] || {};
  };

  /**
   * Save an entire JSON object under a namespace
   * @param {string} namespace - Key area
   * @param {object} jsonData - JSON object to save
   */
  const saveJsonSettings = (namespace, jsonData) => {
    const timestampedData = Object.keys(jsonData).reduce((acc, key) => {
      acc[key] = {
        value: jsonData[key],
        timestamp: new Date().toISOString(),
      };
      return acc;
    }, {});

    setSettings((prev) => ({
      ...prev,
      [namespace]: {
        ...(prev[namespace] || {}),
        ...timestampedData,
      },
    }));
  };

  /**
   * Clear all settings in a specific namespace
   * @param {string} namespace - Key area
   */
  const clearNamespaceSettings = (namespace) => {
    setSettings((prev) => {
      const newSettings = { ...prev };
      delete newSettings[namespace];
      return newSettings;
    });
  };

  /**
   * Clear all settings
   */
  const clearAllSettings = () => {
    setSettings({});
    sessionStorage.removeItem(STORAGE_KEY);
  };

  return (
    <DialogSettingsContext.Provider
      value={{
        saveSetting,
        getSetting,
        getNamespaceSettings,
        saveJsonSettings,
        clearNamespaceSettings,
        clearAllSettings,
      }}
    >
      {children}
    </DialogSettingsContext.Provider>
  );
};

