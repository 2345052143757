import React, { useEffect, useState, useCallback, useMemo } from 'react';
import {
  Box, TextField, Button, IconButton, Snackbar, Alert, Skeleton, Typography,
  TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper, TablePagination, Stack,
  Dialog,
  DialogTitle
} from '@mui/material';
import {
  Add as AddIcon
} from '@mui/icons-material';
import axios from 'axios';
import { TableGrid } from '../../../common/TableGrid';
import { debounce } from 'lodash';
import { orangeColor, CommonSX } from '../../../css/common_sx';

export default function InvoicesGrid() {
  const [invoice, setinvoices] = useState([]);
  const [globalSearch, setGlobalSearch] = useState('');

  const handleSearchChange = useCallback(
    debounce((value) => {
      setGlobalSearch(value);
    }, 300), // wait 300ms before applying the search
    []
  );

  useEffect(() => {
    return () => {
      handleSearchChange.cancel();
    };
  }, [handleSearchChange]);

  const columns = ['Invoice Number', 'Invoice Date', 'Due Date', 'Total Amount', 'Status', 'Actions'];

  return (
    <Box display="flex" flexDirection="column" width="100%" height="100vh" flex={1} padding={2}>
      <Box display="flex" justifyContent="space-between" alignItems="center" padding={2} mb={2} borderRadius={2} boxShadow={3}
        bgcolor={orangeColor}>
        <TextField
          label={`Search `}
          onChange={(e) => handleSearchChange(e.target.value)}
          fullWidth
          sx={{ bgcolor: 'white', borderRadius: 1, mx: 2 }}
        />
        <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={() => {

        }} >Create new Invoice </Button>
      </Box>
      <TableGrid columns={columns} loading={false} >
      </TableGrid>
    </Box>
  )
}
