import React, { useEffect, useState } from 'react';
import {
  Box, TextField, Button, IconButton, Snackbar, Alert, Skeleton, Typography,
  Grid, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper,
  TablePagination, Dialog, DialogTitle, DialogContent, DialogActions, Tabs, Tab, Checkbox
} from '@mui/material';
import axios from 'axios';
import { orangeColor, CommonSX } from '../../css/common_sx';
import TabPanel from '../../common/tabPanel';
import Refresh from '@mui/icons-material/Refresh';
import { TableGrid } from '../../common/TableGrid';
import CRMPickItemDialog from './pick_item';
import CRMItemsPickerDialog from './pick_item';
import { RemoveRounded, TabUnselectedOutlined, TenMpOutlined } from '@mui/icons-material';
import { DateTimePicker, DatePicker } from '@mui/x-date-pickers';
import { DialogSaveButton, DialogCancelButton } from '../../common/DialogButtons';

import AddRounded from '@mui/icons-material/AddRounded';
export default function CRMOrderDetails({ orderID, open, onClose }) {
  const [tabValue, setTabValue] = useState(0);
  const [formData, setFormData] = useState({ items: [] });
  const [loadingItems, setLoadingItems] = useState(true);
  const [showItemsPicker, setshowItemsPicker] = useState(false);

  const fetchOrderDetails = async () => {
    axios.get(`/Office/CRM/Orders/${orderID}`)
      .then((response) => {
        setFormData({ ...formData, ...response.data, items: [] });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fetchOrderItems = async () => {
    axios.get(`/Office/CRM/Orders/${orderID}/Items`)
      .then((response) => {
        setFormData({ ...formData, items: response.data });
        setLoadingItems(false);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const updateOrderDetails = ({ key, value }) => {
    setFormData({ ...formData, [key]: value });
  };

  const addNewItem = (item) => {
    axios.post(`/Office/CRM/Orders/${orderID}/Items`, { item_id: item.id, quantity: 1 })
      .then((response) => { })
      .catch((err) => { });
    setFormData({ ...formData, items: [...formData.items, { ...item, quantity: 1 }] });
  };

  useEffect(() => {
    if (open) {
      setLoadingItems(true);
      fetchOrderDetails();
      fetchOrderItems();
    }
  }, [open]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleSave = () => {
    for (const item of formData.items) {
      axios.put(`/Office/CRM/Orders/${orderID}/Items/${item.id}`, { quantity: item.quantity || 1, price: item.price || 0 })
        .then((response) => { })
        .catch((err) => { });
    }
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      sx={{
        '& .MuiDialog-paper': {
          height: '80vh',
          width: '90vw',
        },
      }}
    >
      <DialogTitle id="form-dialog-title" sx={{ bgcolor: orangeColor }}>
        Order Details for {orderID}
      </DialogTitle>
      <DialogContent>
        <Tabs value={tabValue} onChange={handleTabChange} aria-label="order details tabs">
          <Tab label="General" />
          <Tab label="Items" />
          <Tab label="Documents" />
          <Tab label="Audit" />
        </Tabs>

        {/* Tab Panels */}
        <TabPanel value={tabValue} index={0}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Box display='flex' flexDirection='row'>
                <DatePicker
                  label="Order Date"
                  value={formData.created_date}
                  fullWidth
                />
                <IconButton onClick={() => { }}><Refresh /></IconButton>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Created By"
                value={formData.created_by}
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Created At"
                value={new Date(formData.created_at).toLocaleString()}
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Partner Name"
                value={formData.partner_name}
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <Box display='flex' flexDirection='row'>
                <TextField
                  label="Delivery Warehouse"
                  value={formData.warehouse_name}
                  fullWidth
                  disabled
                />
                <IconButton onClick={() => { }}><Refresh /></IconButton>
              </Box>
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel value={tabValue} index={1}>
          <Box display="flex" flexDirection="row" justifyContent="flex-end">
            <Typography variant="h6">Total Order Value: {formData.items.reduce((acc, item) => acc + item.price * item.quantity, 0)}</Typography>
          </Box>
          <TableGrid
            columns={[
              'Item Name',
              'SKU',
              'Item Qty',
              'Item Unit Price',
              'Total Price',
              'Actions',
            ]}
            rowsPerPage={10}
            enableOuterShell={false}
            loading={loadingItems}
          >
            {formData.items.map((item) => {

              return (
                <TableRow key={item.id}>
                  <TableCell align="center">{item.item_name}</TableCell>
                  <TableCell align="center">{item.sku}</TableCell>
                  <TableCell>
                    <Box display="flex" flexDirection="row" p={0} justifyContent="center" fontSize={12}>
                      {item.quantity}
                      <IconButton
                        sx={{ padding: 0 }}
                        onClick={() =>
                          setFormData({
                            ...formData,
                            items: formData.items.map((i) =>
                              i.id === item.id ? { ...i, quantity: i.quantity + 1 } : i
                            ),
                          })
                        }
                      >
                        <AddRounded />
                      </IconButton>
                      <IconButton
                        sx={{ padding: 0 }}
                        onClick={() =>
                          setFormData({
                            ...formData,
                            items: formData.items.map((i) =>
                              i.id === item.id ? { ...i, quantity: Math.max(1, i.quantity - 1) } : i
                            ),
                          })
                        }
                      >
                        <RemoveRounded />
                      </IconButton>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <TextField
                      value={item.price}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          items: formData.items.map((i) =>
                            i.id === item.id ? { ...i, price: parseFloat(e.target.value) || 0 } : i
                          ),
                        })
                      }
                      type="number"
                      inputProps={{ style: { height: '24px', padding: '0 5px' } }}
                    />
                  </TableCell>
                  <TableCell>{item.price * item.quantity}</TableCell>
                  <TableCell>
                    <IconButton sx={{ padding: 0 }}><RemoveRounded /></IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableGrid>
          <Box display="flex" flexDirection="row" justifyContent="flex-end">
            <Button
              variant="contained"
              color="primary"
              fullWidth
              disabled={false}
              sx={{ marginTop: 2 }}
              onClick={() => setshowItemsPicker(true)}
            >
              Add Item
            </Button>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              disabled={true}
              sx={{ marginTop: 2, marginLeft: 2 }}
            >
              Create Goods Receipt
            </Button>
          </Box>
        </TabPanel>


        <TabPanel value={tabValue} index={2}>
          <Typography variant="h6">Documents</Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Document Name</TableCell>
                  <TableCell>Date Uploaded</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/* Map your documents here */}
                <TableRow>
                  <TableCell>Document 1</TableCell>
                  <TableCell>2024-09-01</TableCell>
                  <TableCell>
                    <Button variant="contained">Download</Button>
                  </TableCell>
                </TableRow>
                {/* More rows */}
              </TableBody>
            </Table>
          </TableContainer>
        </TabPanel>

        <TabPanel value={tabValue} index={3}>
          <Typography variant="h6">Audit</Typography>
          <Typography variant="body1">To be implemented</Typography>
        </TabPanel>
      </DialogContent>
      <DialogActions>
        <DialogSaveButton onClick={handleSave} />
        <DialogCancelButton onClick={onClose} />
      </DialogActions>
      <CRMItemsPickerDialog
        open={showItemsPicker}
        onClose={() => setshowItemsPicker(false)}
        onSelect={(item) => {
          if (item) {
            // Save the item to the order
            addNewItem(item);
          }
        }}
      />
    </Dialog>
  );
}

