const levenshteinDistance = (a, b) => {
  const matrix = Array.from({ length: a.length + 1 }, (_, i) =>
    Array.from({ length: b.length + 1 }, (_, j) => (i === 0 ? j : j === 0 ? i : 0))
  );

  for (let i = 1; i <= a.length; i++) {
    for (let j = 1; j <= b.length; j++) {
      if (a[i - 1] === b[j - 1]) {
        matrix[i][j] = matrix[i - 1][j - 1];
      } else {
        matrix[i][j] = Math.min(
          matrix[i - 1][j],     // Deletion
          matrix[i][j - 1],     // Insertion
          matrix[i - 1][j - 1]  // Substitution
        ) + 1;
      }
    }
  }

  return matrix[a.length][b.length];
};

const similarity = (a, b) => {
  const maxLength = Math.max(a.length, b.length);
  return maxLength === 0 ? 1 : (maxLength - levenshteinDistance(a, b)) / maxLength;
};

// Search function with options
export const searchFunction = (searchValue, searchFields, data) => {
  // Parse options
  const options = { similaritySearch: false, threshold: 0.5 };
  const optionRegex = /(?:\bs:(\d)\b)|(?:\bst:(\d+(?:\.\d+)?))/g;
  let match;

  while ((match = optionRegex.exec(searchValue)) !== null) {
    if (match[1]) options.similaritySearch = match[1] === '1';
    if (match[2]) options.threshold = parseFloat(match[2]);
  }

  // Remove options from the actual search value
  const actualSearchValue = searchValue.replace(optionRegex, '').trim().toLowerCase();

  return data.filter(order =>
    searchFields.some((key) => {
      const fieldValue = (order[key] || '').toString().toLowerCase();

      // Direct match
      if (fieldValue.includes(actualSearchValue)) {
        return true;
      }

      // Similarity match (if enabled)
      if (options.similaritySearch) {
        return similarity(fieldValue, actualSearchValue) >= options.threshold;
      }

      return false;
    })
  );
};

