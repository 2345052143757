import React, { useEffect, useState, useCallback, useMemo } from 'react';
import axios from 'axios';
import {
  Box, TextField, Button, IconButton, Snackbar, Alert, Typography,
  TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper, TablePagination, Breadcrumbs, Link, Stack
} from '@mui/material';
import { debounce } from 'lodash';
import { TableGrid } from '../../common/TableGrid';
import { orangeColor, CommonSX } from '../../css/common_sx';
import { searchFunction } from '../../common/search_function';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

function humanFileSize(bytes, si = false, dp = 1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

  return bytes.toFixed(dp) + ' ' + units[u];
}

export default function FileGrid() {
  const [files, setFiles] = useState([]);
  const [filePath, setFilePath] = useState('');
  const [globalSearch, setGlobalSearch] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [history, setHistory] = useState(['']);
  const [currentIndex, setCurrentIndex] = useState(0);

  const fetchFiles = async (bucket, path) => {
    setLoading(true);
    try {
      axios.get(`/Office/Files/Browse/${bucket}`, { params: { path } })
        .then((response) => {
          setFiles(response.data);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          setError('Failed to fetch files');
          setOpenSnackbar(true);
        });
    } catch (error) {
      setError('Failed to fetch files');
      setOpenSnackbar(true);
    }
  };

  useEffect(() => {
    fetchFiles('pcrms-property-images', filePath);
  }, [filePath]);

  const navigateToPath = (path) => {
    setFilePath(path);
    const newHistory = history.slice(0, currentIndex + 1);
    newHistory.push(path);
    setHistory(newHistory);
    setCurrentIndex(newHistory.length - 1);
  };

  const goBack = () => {
    if (currentIndex > 0) {
      const newIndex = currentIndex - 1;
      setCurrentIndex(newIndex);
      setFilePath(history[newIndex]);
    }
  };

  const goForward = () => {
    if (currentIndex < history.length - 1) {
      const newIndex = currentIndex + 1;
      setCurrentIndex(newIndex);
      setFilePath(history[newIndex]);
    }
  };

  const handleSearchChange = useCallback(
    debounce((value) => setGlobalSearch(value), 300),
    []
  );

  const filteredFiles = useMemo(() => {
    return searchFunction(globalSearch, ['name', 'email', 'phone'], files);
  }, [globalSearch, files]);

  const breadcrumbs = filePath.split('/').filter(Boolean);

  return (
    <Box display="flex" flexDirection="column" width="100%" height="94vh" p={1} >
      {/* Navigation & Search Bar */}
      <Box display="flex" flexDirection='column' padding={2} mb={2} borderRadius={2} boxShadow={3}
        bgcolor={orangeColor}>
        <Box display='flex' justifyContent="space-between" alignItems="center" p={0}>
          <IconButton onClick={goBack} disabled={currentIndex === 0}><ArrowBackIcon /></IconButton>
          <IconButton onClick={goForward} disabled={currentIndex === history.length - 1}><ArrowForwardIcon /></IconButton>
          <TextField
            label="Search"
            onChange={(e) => handleSearchChange(e.target.value)}
            fullWidth
            sx={{ mx: 2, bgcolor: 'white', borderRadius: 1 }}
          />
          <Button variant="contained" color="primary" startIcon={<AddIcon />}>Upload File</Button>
        </Box>
        <Breadcrumbs separator="/" sx={{ padding: 0, pl: 2 }}>
          <Link component="button" onClick={() => navigateToPath('')}>Root</Link>
          {breadcrumbs.map((crumb, idx) => {
            const path = breadcrumbs.slice(0, idx + 1).join('/');
            return (
              <Link key={idx} component="button" onClick={() => navigateToPath(path)}>{crumb}</Link>
            );
          })}
        </Breadcrumbs>
      </Box>

      {/* File Table */}
      <TableGrid columns={['Name', 'Size', 'Actions']} loading={loading}>
        {filteredFiles.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((file, index) => (
          <TableRow key={index} hover onClick={() => {
            if (file.type === 'folder') navigateToPath(file.name);
          }}>
            <TableCell>{file.name}</TableCell>
            <TableCell>{humanFileSize(file.size)}</TableCell>
            <TableCell>
              <IconButton></IconButton>
            </TableCell>
          </TableRow>
        ))}
      </TableGrid>

      {/* Pagination */}
      <TablePagination
        rowsPerPageOptions={[10, 30, 50]}
        component="div"
        count={files.length}
        rowsPerPage={rowsPerPage}
        sx={{ ...CommonSX, mt: 2 }}
        page={page}
        onPageChange={(e, newPage) => setPage(newPage)}
        onRowsPerPageChange={(e) => {
          setRowsPerPage(parseInt(e.target.value, 10));
          setPage(0);
        }}
      />
    </Box>
  );
}

