import React, { createContext, useContext, useState } from 'react';
import { getModuleNameById } from '../dashboard_menu_return';
const MenuContext = createContext();

export const useMenu = () => useContext(MenuContext);

export const MenuProvider = ({ children }) => {
  const [selectedMenuItem, setSelectedMenuItem] = useState(null);
  const [history, setHistory] = useState([]);

  const getHistory = () => {
    return history
  };

  const navigateWithId = (id, args = {}) => {
    let obj = { id: id, args: args, name: getModuleNameById(id) };
    setHistory([...history, obj]);
    setSelectedMenuItem(obj);
  };


  return (
    <MenuContext.Provider value={{ selectedMenuItem, navigateWithId, getHistory }}>
      {children}
    </MenuContext.Provider>
  );
};

