import React, { useEffect, useState, useCallback, useMemo } from 'react';
import {
  Box, TextField, Button, IconButton, Snackbar, Alert, Skeleton, Typography,
  TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper, TablePagination, Stack
} from '@mui/material';
import {
  Add as AddIcon
} from '@mui/icons-material';
import axios from 'axios';
import CRMCreateNewDocument from './documents/create_new_document';
import { orangeColor, CommonSX } from '../../css/common_sx';
import { getStatusIcon } from './document_status_icon';
import { searchFunction } from '../../common/search_function';
import CRMViewDocument from './documents/view_document';
import DocumentStatusTypeEditorDialog from './document_type_status_access';
import { debounce } from 'lodash';
import KeyIcon from '@mui/icons-material/Key';
import { TableGrid } from '../../common/TableGrid';
import { useSnackbar } from '../../common/error_provider';

export default function CRMDocumentsGrid() {
  const [documents, setDocuments] = useState([]);
  const [globalSearch, setGlobalSearch] = useState('');

  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0); // Pagination page
  const [rowsPerPage, setRowsPerPage] = useState(30); // 
  const [openCreateDocument, setOpenCreateDocument] = useState(false);
  const [openViewDocument, setOpenViewDocument] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [openDocumentStatusTypeEditor, setOpenDocumentStatusTypeEditor] = useState(false);
  const { showError } = useSnackbar();
  const fetchDocuments = async () => {
    setLoading(true);
    axios.get('/Office/CRM/Documents').then((response) => {
      setDocuments(response.data);
      setLoading(false);
    }).catch((err) => {
      setLoading(false);
      showError('Error fetching documents');
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    fetchDocuments();
  }, []);

  const filteredDocuments = useMemo(() => {
    return searchFunction(globalSearch,
      ['document_type_name', 'created_at', 'status', 'source_warehouse_name',
        'destination_warehouse_name', 'partner_reference'], documents)
  }, [globalSearch, documents]);

  // Debounce the search input to avoid filtering on every keystroke
  const handleSearchChange = useCallback(
    debounce((value) => {
      setGlobalSearch(value);
    }, 300), // wait 300ms before applying the search
    []
  );

  useEffect(() => {
    return () => {
      handleSearchChange.cancel();
    };
  }, [handleSearchChange]);

  const columns = [
    'Statuses',
    'Document ID',
    'Document Type',
    'Document Create Date',
    'Document Status',
    'Document Source Warehouse',
    'Document Destination Warehouse',
    'Partner',
    'Partner Reference',
    'Order ID',
    'Total Out/In Items',
    'Total Out/In Values'
  ]

  return (
    <Box display="flex" flexDirection="column" width="100%" height="100vh" flex={1} padding={2}>
      <Box display="flex" justifyContent="space-between" alignItems="center" padding={2} mb={2} borderRadius={2} boxShadow={3}
        bgcolor={orangeColor}>
        <TextField
          label={`Search `}
          onChange={(e) => handleSearchChange(e.target.value)}
          fullWidth
          sx={{ bgcolor: 'white', borderRadius: 1, mx: 2 }}
        />
        <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={() => setOpenCreateDocument(true)} >
          Create New Document
        </Button>
        <Button variant="contained" sx={{ mx: 2 }} color="primary" onClick={() => setOpenDocumentStatusTypeEditor(true)}>
          Per Type status Access
        </Button>
      </Box>
      <TableGrid columns={columns} loading={loading} >
        {filteredDocuments.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((document) =>
          (
            <TableRow onClick={() => {
              setSelectedDocument(document);
              setOpenViewDocument(true);
            }} hover
              style={{ cursor: 'pointer' }} key={document.id}>
              <TableCell><Stack direction="row" spacing={2}>
                <KeyIcon sx={{ color: document.count_of_physical_item > 0 ? 'green' : 'gray' }} />
                {getStatusIcon(document.status)}
              </Stack></TableCell>
              <TableCell align='center'>{document.id} {document.document_no}</TableCell>
              <TableCell align='center'>{document.document_type_name}</TableCell>
              <TableCell align='center'>{new Date(document.created_at).toLocaleString()}</TableCell>
              <TableCell size="small"
                sx={{
                  padding: 0, // Remove extra padding
                }} align='center'>{document.status_name}</TableCell>
              <TableCell align='center'>{document.source_warehouse_name}</TableCell>
              <TableCell align='center'>{document.destination_warehouse_name}</TableCell>
              <TableCell align='center'>{document.distributor_name}</TableCell>
              <TableCell align='center'>
                <span
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                  style={{
                    color: 'blue',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                >
                  {document.partner_reference}
                </span></TableCell>
              <TableCell align='center'>N/A</TableCell>
              <TableCell align='center'><text style={{ color: 'red' }}>{document.total_out.qty}</text> {'/'}<text style={{ color: 'green' }}>{document.total_in.qty}</text></TableCell>
              <TableCell align='center'><text style={{ color: 'red' }}>{document.total_out.values}</text> {'/'}<text style={{ color: 'green' }}>{document.total_in.values}</text></TableCell>
            </TableRow>))}
      </TableGrid>
      <TablePagination
        rowsPerPageOptions={[30, 50, 60]}
        component="div"
        sx={{ ...CommonSX, marginTop: '10px' }}
        count={documents.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <CRMCreateNewDocument
        open={openCreateDocument} onClose={(newItem) => {
          if (newItem === null) {
            setOpenCreateDocument(false);
            return;
          }
          if (newItem) {
            setDocuments(prev => [...newItem, ...prev]);
          }
          setOpenCreateDocument(false);
        }} />
      <CRMViewDocument
        open={openViewDocument}
        onClose={(res) => {
          if (res === null) {
            setOpenViewDocument(false);
            return;
          }
          if (res === 1) {
            fetchDocuments();
          }
          setOpenViewDocument(false);
        }}
        document={selectedDocument}
        onSave={(e) =>
          setDocuments(prev => {
            const index = prev.findIndex(x => x.id === e.id);
            if (index > -1) {
              prev[index] = e;
            }
            return [...prev];
          })
        }
        onError={(e) => {
          showError('Error updating/saving document');
        }}
      />
      <DocumentStatusTypeEditorDialog open={openDocumentStatusTypeEditor} onClose={() => setOpenDocumentStatusTypeEditor(false)} />
    </Box>
  )
};
