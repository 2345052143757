import React, { useState, useEffect } from 'react';
import CommonDialog from './CommonDialog';
import axios from 'axios';
import { DatePicker } from '@mui/x-date-pickers';
export default function DateFieldDialog({ open, onClose, onSave, title, defaultValue = '' }) {

  const [value, setValue] = useState('');

  useEffect(() => {
    if (open) setValue(defaultValue);
  }, [open]);

  return (
    <CommonDialog open={open} onClose={onClose} title={title} onSave={() => {
      if (onSave) onSave(value);
      if (onClose) onClose();
    }}>
      <DatePicker
        fullWidth
        label="Value"
        value={value}
        onChange={(e) => setValue(e)}
      />
    </CommonDialog>
  )
};
