import React, { useEffect, useState, useCallback, useMemo } from 'react';
import {
  Box, TextField, Button, IconButton, Snackbar, Alert, Skeleton, Typography,
  Grid, Card, CardContent, CardActionArea, Menu, MenuItem, LinearProgress,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  Modal
} from '@mui/material';
import axios from 'axios';
import { orangeColor, CommonSX } from '../../css/common_sx';
import CRMOrderDetails from './order_details';
import AddIcon from '@mui/icons-material/Add';
import InfoIcon from '@mui/icons-material/Info';
import CRMCreateNewOrder from './orders/create_new_order';
import { TableGrid } from '../../common/TableGrid';
import { debounce } from 'lodash';
import { searchFunction } from '../../common/search_function';

export default function CRMOrders() {
  const [globalFilter, setGlobalFilter] = useState('');
  const [orders, setOrders] = useState([]);
  const [error, setError] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [page, setPage] = useState(0); // Pagination page
  const [rowsPerPage, setRowsPerPage] = useState(30); // Rows per page
  const [loading, setLoading] = useState(true);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [openOrderDetails, setOpenOrderDetails] = useState(false);
  const [openCreateOrder, setOpenCreateOrder] = useState(false);

  const fetchOrders = async () => {
    setLoading(true);
    try {
      axios.get('/Office/CRM/Orders')
        .then((response) => {
          setOrders(response.data);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          setError('Failed to fetch orders');
          setOpenSnackbar(true);
        });
    } catch (error) {
      setError('Failed to fetch orders');
      setOpenSnackbar(true);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  const columns = [
    'Order ID',
    'Order Type',
    'Order Status',
    'Order Priority',
    'Order Date (Per Local)',
    'Order Warehouse',
    'Customer',
    'Order Fulfill Date',
    'Actions'
  ];

  const filteredOrders = useMemo(() => {
    return searchFunction(globalFilter, ['order_id', 'order_type', 'order_status', 'order_priority',
      'created_at', 'warehouse_name', 'partner_name', 'order_fill_date'], orders);
  }, [globalFilter, orders]);

  const handleSearchChange = useCallback(
    debounce((value) => {
      setGlobalFilter(value);
    }, 300), []);

  useEffect(() => {
    return () => {
      handleSearchChange.cancel();
    };
  }, [handleSearchChange]);

  return (
    <Box display="flex" flexDirection="column" width="100%" height="100vh" flex={1} padding={2}>
      <Box display="flex" justifyContent="space-between" alignItems="center" padding={2} mb={2} borderRadius={2} boxShadow={3} bgcolor={orangeColor}>
        <TextField
          label={`Search`}
          onChange={(e) => handleSearchChange(e.target.value)}
          fullWidth
          sx={{ bgcolor: 'white', borderRadius: 1, mx: 2 }}
        />
        <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={() => {
          setOpenCreateOrder(true);
        }} >
          Create New Order
        </Button>
      </Box>
      <TableGrid columns={columns} loading={loading} rowsPerPage={rowsPerPage} >
        {
          filteredOrders.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((order) => (
            <TableRow key={order.id}>
              <TableCell>{order.order_id}</TableCell>
              <TableCell>{order.order_type}</TableCell>
              <TableCell>{order.order_status}</TableCell>
              <TableCell>
                <Box color={parseInt(order.order_priority) == 50 ? 'green' : 'red'}>
                  {order.order_priority}
                </Box>
              </TableCell>
              <TableCell>{new Date(order.created_at).toLocaleString()}</TableCell>
              <TableCell>{order.warehouse_name}</TableCell>
              <TableCell>{order.partner_name}</TableCell>
              <TableCell>{order.order_fill_date ? new Date(order.order_fill_date).toLocaleString() : ''}</TableCell>
              <TableCell>
                <IconButton sx={{ padding: 0 }} onClick={() => {
                  setSelectedOrder(order.id);
                  setOpenOrderDetails(true);
                }} ><InfoIcon fontSize='small' /></IconButton>
              </TableCell>
            </TableRow>
          ))
        }
      </TableGrid>
      <CRMCreateNewOrder open={openCreateOrder}
        onClose={() => setOpenCreateOrder(false)}
        onNew={(item) => {
          setOrders([item, ...orders]);
        }} />
      <CRMOrderDetails orderID={selectedOrder} open={openOrderDetails} onClose={() => setOpenOrderDetails(false)} />
      <TablePagination
        rowsPerPageOptions={[30, 50, 60]}
        component="div"
        sx={{ ...CommonSX, mt: 2 }}
        count={orders.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)}>
        <Alert onClose={() => setOpenSnackbar(false)} severity="error">{error}</Alert>
      </Snackbar>
    </Box>
  )
};
