import React, { useState, useEffect } from 'react';
import { Select, MenuItem, TextField } from '@mui/material';
import CommonDialog from './CommonDialog';

export default function NumberFieldDialog({ open, onClose, onSave, title, defaultValue = '', maxValue, minValue }) {

  const [value, setValue] = useState(0);

  useEffect(() => {
    if (open) setValue(defaultValue);
  }, [open]);
  return (
    <CommonDialog open={open} onClose={onClose} title={title} onSave={() => {
      if (onSave) onSave(value);
      if (onClose) onClose();
    }}>
      <TextField
        fullWidth
        type="number"
        label="Value"
        value={value}
        onChange={(e) => {
          let val = e.target.value;
          if (val === '') {
            setValue('');
            return;
          }
          val = parseInt(val);
          if (val > maxValue) {
            setValue(maxValue);
          } else if (val < minValue) {
            setValue(minValue);
          } else {
            setValue(val);
          }
        }}
      />
    </CommonDialog>
  )
};
