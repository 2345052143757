import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Dialog, DialogTitle, DialogContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, IconButton, TablePagination, Snackbar, Alert, Skeleton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { searchFunction } from '../../common/search_function';
import { debounce } from 'lodash';
import { orangeColor, CommonSX } from '../../css/common_sx';
import { TableGrid } from '../../common/TableGrid';
const CRMItemsPickerDialog = ({ open, onClose, onSelect }) => {
  const [items, setItems] = useState([]);
  const [globalSearch, setGlobalSearch] = useState('');

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [error, setError] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchItems = async () => {
      setLoading(true); // Set loading to true before fetching
      setItems([]); // Clear the items before fetching
      try {
        const response = await axios.get('/Office/CRM/Items');
        const data = response.data;
        setItems(data);
        setGlobalSearch('');
      } catch (error) {
        setError(error.message);
        setSnackbarOpen(true);
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    };

    if (open) {
      fetchItems();

    }
  }, [open]);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRowClick = (itemId) => {
    onSelect(itemId); // Pass the selected item ID to the onSelect callback
    setSelectedItemId(itemId);
    onClose();
  };

  const handleClose = () => {
    onClose(selectedItemId);  // Pass the selected item ID to the onClose callback
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const filteredItems = useMemo(() => {
    return searchFunction(globalSearch,
      ['item_name', 'sku', 'item_type', 'tags', 'created_at'], items)
  }, [globalSearch, items]);

  // Debounce the search input to avoid filtering on every keystroke
  const handleSearchChange = useCallback(
    debounce((value) => {
      setGlobalSearch(value);
    }, 300), // wait 300ms before applying the search
    []
  );

  useEffect(() => {
    return () => {
      handleSearchChange.cancel();
    };
  }, [handleSearchChange]);



  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle bgcolor={orangeColor}>
          Item List
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <TextField
            label="Search"
            onChange={(e) => handleSearchChange(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TableGrid
            columns={['IMG', 'SKU', 'Name', 'Type', 'Tags', 'Created At', 'Created By']}
            rowsPerPage={rowsPerPage}
            loading={loading}
            enableOuterShell={false}  >
            {
              filteredItems.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) => (
                <TableRow
                  key={item.id}
                  onClick={() => handleRowClick(item)} // Capture the item ID on row click
                  hover
                  style={{ cursor: 'pointer' }}
                >
                  <TableCell>
                    <img src={item.images.length > 0 ? item.images[0] : ''} alt={item.name} style={{ width: '25px', height: '25px', objectFit: 'cover' }} />
                  </TableCell>
                  <TableCell>{item.sku}</TableCell>
                  <TableCell>{item.item_name}</TableCell>
                  <TableCell>{item.item_type}</TableCell>
                  <TableCell>{item.tags.join(', ')}</TableCell>

                  <TableCell>{new Date(item.created_at).toLocaleString()}</TableCell>
                  <TableCell>{item.created_by}</TableCell>
                </TableRow>
              ))
            }
          </TableGrid>
          <TablePagination
            rowsPerPageOptions={[20, 30, 50]}
            component="div"
            sx={{ ...CommonSX, mt: 2 }}
            count={items.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </DialogContent>
      </Dialog>

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
    </>
  );
};

export default CRMItemsPickerDialog;

