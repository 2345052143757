// SnackbarProvider.jsx

import React, { createContext, useContext, useState } from 'react';
import { Snackbar, Alert, Box } from '@mui/material';

// Create Context
const SnackbarContext = createContext(null);

// Custom Hook
export const useSnackbar = () => {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error('useSnackbar must be used within a SnackbarProvider');
  }
  return context;
};

// Snackbar Provider Component
export const SnackbarProvider = ({ children }) => {
  const [messages, setMessages] = useState([]);

  // Add a new message to the queue
  const showMessage = (message, severity) => {
    setMessages((prev) => [...prev, { id: Date.now(), message, severity }]);
  };

  const showError = (message) => showMessage(message, 'error');
  const showInfo = (message) => showMessage(message, 'info');

  // Remove a specific message from the queue
  const handleClose = (id) => {
    setMessages((prev) => prev.filter((msg) => msg.id !== id));
  };

  return (
    <SnackbarContext.Provider value={{ showError, showInfo }}>
      {children}
      <Box>
        {messages.map((msg) => (
          <Snackbar
            key={msg.id}
            open={true}
            autoHideDuration={6000}
            onClose={() => handleClose(msg.id)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          >
            <Alert
              onClose={() => handleClose(msg.id)}
              severity={msg.severity}
              sx={{ width: '100%' }}
            >
              {msg.message}
            </Alert>
          </Snackbar>
        ))}
      </Box>
    </SnackbarContext.Provider>
  );
};

