import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, TextField, Checkbox, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, withTheme } from '@mui/material';
import axios from 'axios';
import { Viewer, Differ } from 'json-diff-kit';
import 'json-diff-kit/dist/viewer.css';
import { orangeColor } from '../../css/common_sx';

const THistoryViewer = ({ args }) => {
  const [history, setHistory] = useState([]);
  const [searchId, setSearchId] = useState('');
  const [oldData, setOldData] = useState();
  const [newData, setNewData] = useState();
  const [liveData, setLiveData] = useState(true);

  const fetchHistory = async () => {
    console.debug("Fetching history");
    let url = `/Office/Logging/view/${args.org.qry}/${searchId}`;
    if (liveData) {
      url += "?live=1";
    }
    try {
      const response = await axios.get(url);
      setHistory(response.data);
    } catch (error) {
      console.error('Error fetching history:', error);
    }
  };

  const viewerProps = {
    indent: 4,
    color: 'black',
    lineNumbers: true,
    highlightInlineDiff: true,
    inlineDiffOptions: {
      mode: 'word',
      wordSeparator: ' ',
    },
    hideUnchangedLines: true,
    syntaxHighlight: false,
    virtual: false,
  };

  const d = new Differ({
    detectCircular: true,
    maxDepth: null,
    showModifications: true,
    arrayDiffMethod: 'lcs',
    ignoreCase: false,
    ignoreCaseForKey: false,
    recursiveEqual: true,
  });

  const diff = d.diff(oldData, newData);

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      height="100%"
      p={2}
      style={{ color: 'white' }}
    >
      <Box flex="1" display="flex" flexDirection="row">
        <Box flex="1" mr={2} mb={2} color='black' p={2} style={{ backgroundColor: 'white', borderRadius: '5px' }}>
          <Box>
            <Typography variant='body1'>Reporter: {args.org.qry}</Typography>
          </Box>
          <TextField
            label="Search"
            variant="outlined"
            value={searchId}
            onChange={(e) => setSearchId(e.target.value)}
            sx={{
              backgroundColor: 'white',
              borderRadius: 1,
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: orangeColor,
                },
              },
            }}
          />
          <Button
            variant="contained"
            sx={{
              marginLeft: '10px',
              backgroundColor: orangeColor,
              '&:hover': {
                backgroundColor: 'darkorange',
              },
            }}
            onClick={fetchHistory}
          >
            Search
          </Button>
          <Box display="flex" alignItems="center" marginTop="10px">
            <Typography>Live Data</Typography>
            <Checkbox
              checked={liveData}
              onChange={(e) => setLiveData(e.target.checked)}
              sx={{ color: orangeColor }}
            />
          </Box>
        </Box>

        <Box flex="3" display="flex" flexDirection="row" mb={2}>

          <Box flex="3" color='black' p={2} style={{ backgroundColor: 'white', borderRadius: '5px' }}>
            <Viewer diff={diff} {...viewerProps} />
          </Box>
        </Box>
      </Box>

      <Box flex="1">
        <Box position="relative" width="100%" height="100%">
          <Box position="absolute" overflow="auto" top="0" left="0" right="0" bottom="0" >
            <TableContainer component={Paper}>
              <Table stickyHeader size='small'>
                <TableHead>
                  <TableRow style={{ backgroundColor: orangeColor }}>
                    <TableCell>ID</TableCell>
                    <TableCell>Operation</TableCell>
                    <TableCell>Table Name</TableCell>
                    <TableCell>Who</TableCell>
                    <TableCell>At</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {history.map((item) => (
                    <TableRow
                      key={item.id}
                      onDoubleClick={() => {
                        setOldData(item.old_val);
                        setNewData(item.new_val);
                      }}
                      hover
                      sx={{ '&:hover': { backgroundColor: '#f5f5f5' } }}
                    >
                      <TableCell>{item.id}</TableCell>
                      <TableCell>{item.operation}</TableCell>
                      <TableCell>{item.tabname}</TableCell>
                      <TableCell>{item.ro_comment}</TableCell>
                      <TableCell>{new Date(item.tstamp).toLocaleString()}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default THistoryViewer;

