import React, { useEffect, useState } from 'react';
import {
  Dialog, DialogActions, DialogContent, DialogTitle,
  Box, Divider, TextField, Typography, Grid, Button,
  Step, StepLabel, Stepper,
  Paper
} from '@mui/material'
import { DialogSaveButton, DialogCancelButton } from '../../../common/DialogButtons';
import { orangeColor } from '../../../css/common_sx';
import CRMPartnerGridWithSearch from '../partners/partner_grid_with_search';
import CRMWarehouseTableGridWithSearch from '../warehouses/warehouse_grid_with_search';
import axios from 'axios';
export default function CRMCreateNewOrder({ open, onClose, onNew }) {
  const [activeStep, setActiveStep] = useState(0);
  const [partner, setPartner] = useState(null);
  const [warehouse, setWarehouse] = useState(null);
  const [orderDetails, setOrderDetails] = useState({});

  const updateOrderDetails = (key, value) => {
    setOrderDetails({ ...orderDetails, [key]: value });
  }

  useEffect(() => {
    if (open) {
      setPartner(null);
      setOrderDetails({});
      setActiveStep(0);
    }
  }, [open]);

  const saveOrder = () => {
    if (partner && warehouse) {
      axios.post('/Office/CRM/Orders', {
        partner_id: partner.id,
        warehouse_id: warehouse.id
      })
        .then((response) => {
          if (response.status === 200) {
            axios.get(`/Office/CRM/Orders/${response.data.id}`)
              .then((response) => {
                onNew(response.data);
                onClose();
              })
              .catch((err) => { });
          }
        }).catch((err) => {
          console.error(err);
        });
    }
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle sx={{ backgroundColor: orangeColor, color: 'white' }}>Create New Order (This is a plan not final Till you Finalize the order)</DialogTitle>
      <DialogContent>
        <Box mt={2}>
          <Stepper activeStep={activeStep}>
            <Step >
              <StepLabel>Choose Partner</StepLabel>
            </Step>
            <Step>
              <StepLabel>Delivery Warehouse</StepLabel>
            </Step>
            <Step>
              <StepLabel>Order Summary</StepLabel>
            </Step>
          </Stepper>
          <Divider />
          <Box mt={2}>
            {activeStep === 0 && <CRMPartnerGridWithSearch onSelect={(partner) => {
              setPartner(partner);
              updateOrderDetails('partner_id', partner.id);
              setActiveStep(1);
            }} />}
            {activeStep === 1 &&
              <CRMWarehouseTableGridWithSearch onSelect={(warehouse) => {
                updateOrderDetails('warehouse_id', warehouse.id);
                setWarehouse(warehouse);
                setActiveStep(2);
              }} />
            }
            {activeStep === 2 &&
              <Box>
                <Paper elevation={3} sx={{ padding: 2 }}>
                  <Typography variant="h6">Selected Partner</Typography>
                  <Typography>{partner.partner_name}</Typography>
                  <Typography>{partner.partner_email}</Typography>
                  <Typography>{partner.partner_phone}</Typography>
                </Paper>
                <Paper elevation={3} sx={{ padding: 2, marginTop: 2 }}>
                  <Typography variant="h6">Delivery Warehouse</Typography>
                  <Typography>{warehouse.name}</Typography>
                  <Typography>{warehouse.full_address}</Typography>
                </Paper>
              </Box>
            }
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <DialogCancelButton onClick={onClose} />
        <DialogSaveButton onClick={saveOrder} disabled={activeStep !== 2} />
      </DialogActions>
    </Dialog >
  )
}
