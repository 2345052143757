import React, { useState, useEffect } from 'react';
import { Select, MenuItem, CircularProgress, Stack } from '@mui/material';
import CommonDialog from './CommonDialog';
import axios from 'axios';

export default function SelectFieldDialog({ open, onClose, onSave, title, defaultValue = "",
  in_items = [], url = null, keyField = 'id', valueField = 'label' }) {

  const [value, setValue] = useState(null);
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (!open) return;
    setItems([]);
    if (url) {
      setLoading(true);
      axios.get(typeof url === 'function' ? url() : url)
        .then(response => {
          setItems(response.data);
          setLoading(false);
        })
        .catch(error => {
          console.error(error);
          setLoading(false);
        });
    } else {
      setItems(in_items);
    }
    setValue(defaultValue);
  }, [open]);

  return (
    <CommonDialog open={open} onClose={onClose} title={title} onSave={() => {
      if (onSave) {
        onSave({ [keyField]: value, [valueField]: items.find(item => item[keyField] === value)[valueField] });
      };
      if (onClose) onClose();
    }}>
      <Stack spacing={2} direction='row'>
        {loading && <CircularProgress />}
        <Select
          value={value}
          onChange={(e) => setValue(e.target.value)}
          fullWidth
          disabled={loading}
        >
          {items.map((item, index) => (
            <MenuItem key={index} value={item[keyField]}>{item[valueField]}</MenuItem>
          ))}
        </ Select>
      </Stack>
    </CommonDialog>
  )
};
