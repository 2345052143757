import React, { useEffect, useState, useCallback, useMemo } from 'react';
import {
  Box, TextField, Button, IconButton, Snackbar, Alert, Skeleton, Typography,
  TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper, TablePagination, Stack
} from '@mui/material';

export const TableGrid = ({ columns, children, loading = false, rowsPerPage = 30, enableOuterShell = true }) => {
  const TableWrapper = !enableOuterShell ? React.Fragment : Box;

  return (
    <TableWrapper {...(!enableOuterShell ? {} : { position: "relative", width: "100%", height: "100%" })}>
      <TableContainer component={Paper} sx={!enableOuterShell ? {} : { top: '0', bottom: '0', position: 'absolute' }}>

        <Table size='small'>
          <TableHead>
            <TableRow>
              {columns.map((column) => {
                if (typeof column === 'object') {
                  return (
                    <TableCell align='center'>
                      <Box display='flex' flexDirection='row'>
                        {column.label}
                        {column.searchable &&
                          <TextField
                            size='small'
                            inputProps={{
                              style: {
                                height: '24px',
                                padding: '0 0px',
                              },
                            }}
                            label='Search'
                            sx={{ marginLeft: '5px', padding: 0, marginRight: '5px' }}
                            onChange={(e) => column.onSearch(e.target.value)}
                          />
                        }
                        {column.actions && column.actions.map((action) => (
                          <IconButton sx={{ marginLeft: '5px', padding: 0 }} onClick={action.onClick}>
                            <action.icon />
                          </IconButton>
                        ))}
                      </Box></TableCell>)
                } else {
                  return (
                    <TableCell align='center'>{column}</TableCell>)
                }
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? Array.from(new Array(rowsPerPage)).map((_, index) => (
              <TableRow key={index}>
                {Array.from(new Array(columns.length)).map((_, index) => (
                  <TableCell><Skeleton variant="text" /></TableCell>))}
              </TableRow>
            )) : children}
          </TableBody>
        </Table>
      </TableContainer>
    </TableWrapper>

  )
}
