import React, { useEffect, useState } from 'react';
import { Box, Typography, Paper } from '@mui/material';
import Chart from 'react-apexcharts'
import axios from 'axios';
import { ListBox } from 'primereact/listbox';
import { SelectButton } from 'primereact/selectbutton';
import { Dropdown } from 'primereact/dropdown';
import '../../css/screens/statistics/pacerep.css'
import { Button } from 'primereact/button';
import { PropertiesList } from '../properties/basicsidebar';
import './ZItem.css';
import { Checkbox } from 'primereact/checkbox';
import { orangeColor } from '../../css/common_sx';
const PeriodType = [{ name: 'Daily', value: 1 }, { name: 'Weekly', value: 2 }, { name: 'Monthly', value: 3 }];
const CompareType = [{ name: 'None', value: 0 }, { name: 'Last Year', value: 1 }, { name: 'Last 2 Year', value: 2 }, { name: 'Last 3 Year', value: 3 }];

function PaceItem({ property_id, period_type, compare_type, property_name, calcOverllDiff }) {
  const [selectedPropertiesData, setSelectedPropertiesData] = useState([]);
  const [selectedComparePropertyData, setSelectedComparePropertyData] = useState([]);
  const [selectedPropertyPriceData, setSelectedPropertyPriceData] = useState([]);
  const [selectedComparePropertyPriceData, setSelectedComparePropertyPriceData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const pageSize = 12;

  const tableData = Object.keys(selectedPropertiesData).map(monthYearKey => {
    const [year, month] = monthYearKey.split('-');
    return {
      year,
      month,
      ...selectedPropertiesData[monthYearKey]
    };
  }).slice(currentIndex, currentIndex + pageSize);


  const tablePriceData = Object.keys(selectedPropertyPriceData).map(monthYearKey => {
    const [year, month] = monthYearKey.split('-');
    return {
      year,
      month,
      ...selectedPropertyPriceData[monthYearKey]
    };
  }).slice(currentIndex, currentIndex + pageSize);



  const tableCompareData = Object.keys(selectedComparePropertyData).map(monthYearKey => {
    const [year, month] = monthYearKey.split('-');
    return {
      year,
      month,
      ...selectedComparePropertyData[monthYearKey]
    };
  }).slice(currentIndex, currentIndex + pageSize);


  const tableComparePriceData = Object.keys(selectedComparePropertyPriceData).map(monthYearKey => {
    const [year, month] = monthYearKey.split('-');
    return {
      year,
      month,
      ...selectedComparePropertyPriceData[monthYearKey]
    };
  }).slice(currentIndex, currentIndex + pageSize);


  const calculatePercentageDifference = (value1, value2) => {
    if (value1 === 0) return 100; // If the first value is 0, return 100% difference
    return Math.abs(((value1 - value2) / value1) * 100);
  }


  const getColorAndArrow = (currentSum, comparedSum) => {
    if (currentSum > comparedSum) {
      return { color: 'green', arrow: '↑' };
    } else if (currentSum < comparedSum) {
      return { color: 'red', arrow: '↓' };
    } else {
      return { color: 'black', arrow: '→' }; // or whatever default color and arrow direction you want for equal values
    }
  };
  const handleForwardClick = () => {
    console.log(Object.keys(selectedPropertiesData).length)
    if (currentIndex + pageSize <= Object.keys(selectedPropertiesData).length) {
      setCurrentIndex(prev => prev + pageSize); // Update currentIndex
    } else {
      // Handle the case where currentIndex is already at its maximum value
      console.log('Cannot go beyond the maximum index.');
    }
  };

  const handleBackwardClick = () => {
    setCurrentIndex(prev => Math.max(prev - pageSize, 0));
  };


  const fetchPropertiPriceByYear = async (propertyId, year) => {
    let response = await axios.get('https://uploads.shortletsmalta.com/Office/Property/Listing/Price?propertyid=' + propertyId + '&year=' + year)
    let groupData = [];
    switch (period_type) {
      case 1:
        groupData = response.data.reduce((acc, item) => {
          const dayKey = `${item.ref_month}-${item.ref_day}`;;
          if (!acc[dayKey]) {
            acc[dayKey] = {
              average_useable_price: 0
            };
          }
          if (item.usable_price !== null) {
            acc[dayKey].average_useable_price += parseFloat(item.usable_price.replace('$', '') || 0);
          }
          return acc;
        }, {});
        break;
      case 3:
        groupData = response.data.reduce((acc, item) => {
          const monthKey = `${item.ref_year}-${item.ref_month}`;
          if (!acc[monthKey]) {
            acc[monthKey] = {
              total_useable_price: 0,
              count: 0
            };
          }
          if (item.usable_price !== null) {
            acc[monthKey].total_useable_price += parseFloat(item.usable_price.replace('$', '') || 0);
            acc[monthKey].count++;
          }
          return acc;
        }, {});

        // Calculate average for each month
        for (let key in groupData) {
          groupData[key].average_useable_price = groupData[key].total_useable_price / groupData[key].count;
        }
        break;
      default:
        groupData = [];
    }
    console.log(`Property Price data (${year}):`, groupData)
    return groupData;
  }

  const fetchCurrentYear = async () => {
    console.log('Starting fetchig data');
    //let currentYear = await fetchPropertiByYear(property_id, new Date().getFullYear());
    //setSelectedPropertiesData(currentYear);
    //let currentYearPrice = await fetchPropertiPriceByYear(property_id, new Date().getFullYear());
    //setSelectedPropertyPriceData(currentYearPrice);
    //if (compare_type !== 0) {
    //    let compareYear = await fetchPropertiByYear(property_id, new Date().getFullYear() - compare_type);
    //    setSelectedComparePropertyData(compareYear);
    //    let compareYearPrice = await fetchPropertiPriceByYear(property_id, new Date().getFullYear() - compare_type)
    //    setSelectedComparePropertyPriceData(compareYearPrice);
    //}
    //setCurrentIndex(0);
    //
    const [currentYearData, currentYearPriceData, compareYearData, compareYearPriceData] = await Promise.all([
      fetchPropertiByYear(property_id, new Date().getFullYear()),
      fetchPropertiPriceByYear(property_id, new Date().getFullYear()),
      compare_type !== 0 ? fetchPropertiByYear(property_id, new Date().getFullYear() - compare_type) : Promise.resolve(null),
      compare_type !== 0 ? fetchPropertiPriceByYear(property_id, new Date().getFullYear() - compare_type) : Promise.resolve(null)
    ]);

    // Set state or perform other actions with the retrieved data
    setSelectedPropertiesData(currentYearData);
    setSelectedPropertyPriceData(currentYearPriceData);
    if (compare_type !== 0) {
      setSelectedComparePropertyData(compareYearData);
      setSelectedComparePropertyPriceData(compareYearPriceData);
    }
    setCurrentIndex(0);
  }
  useEffect(() => {
    if (property_id !== null) {
      fetchCurrentYear();
    }
  }, [property_id, period_type, compare_type]);

  const fetchPropertiByYear = async (propertyId, year) => {

    console.log('Fetching Properties for year ', year, 'with PropertyId: ', propertyId);
    let response = await axios.get('https://uploads.shortletsmalta.com/Office/Statistics/SalesReport?propertyid=' + propertyId + '&year=' + year)
    console.debug('Fetch Response: ', response)
    let groupData = [];
    console.log('Selected Period Type: ', period_type, ' - ', PeriodType.find((item) => item.value === period_type).name);
    switch (period_type) {
      case 1:
        groupData = response.data.reduce((acc, item) => {
          const dayKey = `${item.ref_month}-${item.ref_day}`;;
          if (!acc[dayKey]) {
            acc[dayKey] = {
              priceSum: 0,
              rentSum: 0,
              itemCount: 0
            };
          }
          if (item.price !== null) {
            acc[dayKey].priceSum += parseFloat(item.price.replace('$', '') || 0);
          }

          // Parse and sum rent if it's not null                                         
          if (item.rent !== null) {
            acc[dayKey].rentSum += parseFloat(item.rent.replace('$', '') || 0);
            acc[dayKey].itemCount++;

          }

          return acc;
        }, {});
        break;
      case 3:
        groupData = response.data.reduce((acc, item) => {
          //console.debug(`Item : ${item.price}, ${item.rent}`)
          const monthYearKey = `${item.ref_year}-${item.ref_month}`;
          if (!acc[monthYearKey]) {
            acc[monthYearKey] = {
              priceSum: 0,
              rentSum: 0,
              itemCount: 0
            };
            //console.debug(`(${monthYearKey}) Creating new Acc key for (${monthYearKey}):`, acc[monthYearKey])
          }
          if (item.price !== null) {
            //console.debug(`(${monthYearKey}) (PRICE) - Item : ${item.price}, ${item.rent}`)
            acc[monthYearKey].priceSum += parseFloat(item.price.replace('$', '') || 0);
          }

          // Parse and sum rent if it's not null
          if (item.rent !== null) {
            //console.debug(`(${monthYearKey}) (RENT)  - Item : ${item.price}, ${item.rent}`)
            //console.debug(`(${monthYearKey}) (RENT) Updateing acc key ${monthYearKey} with rent ${parseFloat(item.rent.replace('$', ''))}`)
            acc[monthYearKey].rentSum += parseFloat(item.rent.replace('$', '') || 0);
            //console.debug(`(${monthYearKey}) (RENT) Updateing acc key ${monthYearKey} with rent ${parseFloat(item.rent.replace('$', '') || 0)} with final amount: ${acc[monthYearKey].rentSum}`)

            acc[monthYearKey].itemCount++;

          }
          return acc;
        }, {});
        break;
      default:
    }
    console.log(`Property data (${year}):`, groupData);
    return groupData;
  }



  let options = {
    chart: {
      id: 'apexchart-example',
      foreColor: '#373d3f'
    },
    xaxis: {
      categories: tableData.map(data => `${data.year}-${data.month}`)
    },
    yaxis: []
  }
  let series = []
  if (compare_type !== 0) {
    if (tableData !== undefined && tableData.length > 0) {

      series.push({
        name: 'Count',
        data: tableData.map(data => data.itemCount),
        type: 'column'
      })
    }
    if (tableCompareData !== undefined && tableCompareData.length > 0) {

      series.push({
        name: 'Count Compare',
        data: tableCompareData.map(data => data.itemCount),
        type: 'column'
      });
    }
    if (tablePriceData !== undefined && tablePriceData.length > 0) {

      series.push({
        name: 'Average Rate',
        data: tablePriceData.map(data => data.average_useable_price.toFixed(2)),
        type: 'line'
      });
    }
    if (tableComparePriceData !== undefined && tableComparePriceData.length > 0) {

      series.push({
        name: 'Average Rate Compare',
        type: 'line',
        data: tableComparePriceData.map(data => data.average_useable_price.toFixed(2))
      });
    }
    if ((tableCompareData !== undefined && tableCompareData.length > 0) && (tableData !== undefined && tableData.length > 0)) {
      options.yaxis.push({
        decimalsInFloat: 2,
        min: (min) => { return 0; },
        max: (max) => {
          let combinedData = [...tableData, ...tableCompareData];
          //console.log(combinedData);
          const maxItemCountObject = combinedData.reduce((acc, curr) => {
            return acc.itemCount > curr.itemCount ? acc : curr;
          }, combinedData[0]);

          //console.log('Max Value: ', maxItemCountObject);
          return maxItemCountObject.itemCount;
        }
      });
    };
    options.yaxis.push({ show: false });
    if ((tablePriceData !== undefined && tablePriceData.length > 0) && (tableComparePriceData !== undefined && tableComparePriceData.length > 0)) {
      options.yaxis.push({
        opposite: true,
        decimalsInFloat: 2,
        min: (min) => { return 0; },
        max: (max) => {
          let combinedData = [...tablePriceData, ...tableComparePriceData];
          //console.log(combinedData);
          const maxItemCountObject = combinedData.reduce((acc, curr) => {
            return acc.average_useable_price > curr.average_useable_price ? acc : curr;
          }, combinedData[0]);

          //console.log('Max Value of Price: ', maxItemCountObject);
          return maxItemCountObject.average_useable_price;
        }
      });
    };
    options.yaxis.push({ show: false });

  } else {
    if (tableData !== undefined && tableData.length > 0) {
      series.push({
        name: 'Count',
        data: tableData.map(data => data.itemCount),
        type: 'column'
      });
    }
    if (tablePriceData !== undefined && tablePriceData.length > 0) {
      series.push({
        name: 'Average Rate',
        data: tablePriceData.map(data => data.average_useable_price.toFixed(2)),
        type: 'line'
      });
    }
    options.yaxis = [{
      decimalsInFloat: 2,
      min: (min) => { return 0; },
      max: (max) => {
        if (!tableData) { return 0; }
        let combinedData = [...tableData];
        //console.log(combinedData);
        const maxItemCountObject = combinedData.reduce((acc, curr) => {
          return acc.itemCount > curr.itemCount ? acc : curr;
        }, combinedData[0]);

        //console.log('Max Value: ', maxItemCountObject);
        if (!maxItemCountObject) { return 0; }
        return maxItemCountObject.itemCount;
      }
    }, {
      opposite: true,
      decimalsInFloat: 2,
      min: (min) => { return 0; },
      max: (max) => {
        if (!tablePriceData) { return 0; }
        let combinedData = [...tablePriceData];
        //console.log(combinedData);
        const maxItemCountObject = combinedData.reduce((acc, curr) => {
          return acc.average_useable_price > curr.averae_useable_price ? acc : curr;
        }, combinedData[0]);
        if (!maxItemCountObject) { return 0; }
        //console.log('Max Value of Price: ', maxItemCountObject);
        return maxItemCountObject.average_useable_price;
      }
    }]

  }

  if (series.length === 0) {
    series.push({
      name: 'No Data',
      data: [],
      type: 'line'
    });
  }

  return (
    <Box m={1} borderRadius={1} bgcolor='white'>
      <Typography>{property_name}</Typography>
      <Box overflow="hidden">
        <Chart options={options} series={series} width="100%" height="500px" />
      </Box>
      <table class="styled-table" style={{ width: '100%' }}>
        <thead>
          <tr>
            <th style={{ width: '20%' }}>Metrics</th>
            {tableData.map(data => (
              <th key={data.year + '-' + data.month}>{`${data.year}-${data.month}`}</th>
            ))}
            {calcOverllDiff !== false && (
              <th>Overall difference</th>
            )}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><b>Reservation nights</b></td>
            {tableData.map(data => (
              <td key={data.year + '-' + data.month}><b>{data.itemCount}</b></td>
            ))}
            {calcOverllDiff !== false && (
              <td>{tableData.reduce((sum, data) => sum + data.itemCount, 0)}</td>
            )}
          </tr>
          {compare_type !== 0 && (
            <tr>
              <td> Reservation nights ({CompareType.find((item) => item.value === compare_type).name} Comparison)</td>
              {tableCompareData.map(data => (
                <td key={data.year + '-' + data.month}>{data.itemCount}</td>
              ))}
              {calcOverllDiff !== false && (
                <td>{tableCompareData.reduce((sum, data) => sum + data.itemCount, 0)}</td>

              )}
            </tr>)}
          {compare_type !== 0 && (
            <tr>
              <td>Difference</td>
              {tableData.map((data, index) => {
                const compareData = tableCompareData[index];
                if (!compareData) return <td key={data.year + '-' + data.month}></td>; // Handle undefined compareData  

                const percentageDifference = calculatePercentageDifference(data.itemCount, compareData.itemCount).toFixed(2);
                const { color, arrow } = getColorAndArrow(data.itemCount, compareData.itemCount);

                return (
                  <td key={data.year + '-' + data.month} style={{ color }}>
                    {arrow} {percentageDifference}%
                  </td>
                );
              })}
              {calcOverllDiff !== false && (() => {
                const orgData = tableData.reduce((sum, data) => sum + data.itemCount, 0);
                const compData = tableCompareData.reduce((sum, data) => sum + data.itemCount, 0)
                console.debug('Nigts overall diff', orgData, compData);
                const percentageDifference = calculatePercentageDifference(orgData, compData).toFixed(2);
                const { color, arrow } = getColorAndArrow(orgData, compData);

                return (
                  <td style={{ color }}>
                    {arrow} {percentageDifference}%
                  </td>
                );
              })()}

            </tr>
          )}

          <tr>
            <td><b>Price Value Sum</b></td>
            {tableData.map(data => (
              <td key={data.year + '-' + data.month}><b>{data.priceSum.toFixed(2)}</b></td>
            ))}
            {calcOverllDiff !== false && (
              <td>{parseFloat(tableData.reduce((sum, data) => sum + data.priceSum, 0)).toFixed(2)}</td>
            )}
          </tr>


          {compare_type !== 0 && (
            <tr>
              <td> Price Value Sum ({CompareType.find((item) => item.value === compare_type).name} Comparison)</td>
              {tableCompareData.map(data => (
                <td key={data.year + '-' + data.month}>{data.priceSum.toFixed(2)}</td>
              ))}
              {calcOverllDiff !== false && (
                <td>{parseFloat(tableCompareData.reduce((sum, data) => sum + data.priceSum, 0)).toFixed(2)}</td>
              )}
            </tr>
          )}
          {compare_type !== 0 && (
            <tr>
              <td>Difference</td>
              {tableData.map((data, index) => {
                const compareData = tableCompareData[index];
                if (!compareData) return <td key={data.year + '-' + data.month}></td>; // Handle undefined compareData

                const percentageDifference = calculatePercentageDifference(data.priceSum, compareData.priceSum).toFixed(2);
                const { color, arrow } = getColorAndArrow(data.priceSum, compareData.priceSum);

                return (
                  <td key={data.year + '-' + data.month} style={{ color }}>
                    {arrow} {percentageDifference}%
                  </td>
                );
              })}
              {calcOverllDiff !== false && (() => {
                const orgData = tableData.reduce((sum, data) => sum + data.priceSum, 0);
                const compData = tableCompareData.reduce((sum, data) => sum + data.priceSum, 0)
                console.debug('Nigts overall diff', orgData, compData);
                const percentageDifference = calculatePercentageDifference(orgData, compData).toFixed(2);
                const { color, arrow } = getColorAndArrow(orgData, compData);

                return (
                  <td style={{ color }}>
                    {arrow} {percentageDifference}%
                  </td>
                );
              })()}

            </tr>
          )}
          <tr>
            <td><b>Revenue</b></td>
            {
              tableData.map((data, index) => {
                return <td>
                  <b>
                    {(data.itemCount > 0 ? (data.priceSum / data.itemCount).toFixed(2) : "0")}
                  </b>
                </td>
              })}
            {calcOverllDiff !== false && (
              <td>{parseFloat(tableData.reduce((sum, data) => sum + parseFloat(data.itemCount > 0 ? (data.priceSum / data.itemCount).toFixed(2) : "0"), 0)).toFixed(2)}</td>)}
          </tr>
          {compare_type !== 0 && (
            <tr>
              <td> Revenue ({CompareType.find((item) => item.value === compare_type).name} Comparison)</td>
              {tableCompareData.map(data => (
                <td key={data.year + '-' + data.month}>{(data.priceSum / data.itemCount).toFixed(2)}</td>
              ))}
              {calcOverllDiff !== false && (
                <td>{parseFloat(tableCompareData.reduce((sum, data) => sum + parseFloat(data.itemCount > 0 ? (data.priceSum / data.itemCount).toFixed(2) : "0"), 0)).toFixed(2)}</td>)}

            </tr>
          )}
          {compare_type !== 0 && (
            <tr>
              <td>Difference</td>
              {tableData.map((data, index) => {
                const compareData = tableCompareData[index];
                if (!compareData) return <td key={data.year + '-' + data.month}></td>; // Handle undefined compareData

                const percentageDifference = calculatePercentageDifference((data.priceSum / data.itemCount), (compareData.priceSum / compareData.itemCount)).toFixed(2);
                const { color, arrow } = getColorAndArrow(data.priceSum, compareData.priceSum);

                return (
                  <td key={data.year + '-' + data.month} style={{ color }}>
                    {arrow} {percentageDifference}%
                  </td>
                );
              })}
              {calcOverllDiff !== false && (() => {
                const orgData = parseFloat(tableData.reduce((sum, data) => sum + parseFloat(data.itemCount > 0 ? (data.priceSum / data.itemCount).toFixed(2) : "0"), 0)).toFixed(2);
                const compData = parseFloat(tableCompareData.reduce((sum, data) => sum + parseFloat(data.itemCount > 0 ? (data.priceSum / data.itemCount).toFixed(2) : "0"), 0)).toFixed(2);
                const percentageDifference = calculatePercentageDifference(orgData, compData).toFixed(2);
                const { color, arrow } = getColorAndArrow(orgData, compData);

                return (
                  <td style={{ color }}>
                    {arrow} {percentageDifference}%
                  </td>
                );
              })()}
            </tr>
          )}
          <tr>
            <td><b>(Cal) Avarage Daily Rate</b></td>
            {tablePriceData.map(data => (
              <td key={data.year + '-' + data.month}><b>{data.average_useable_price.toFixed(2)}</b></td>
            ))}
          </tr>
          {compare_type !== 0 && (
            <tr>
              <td>(Cal) Avarage Daily Rate ({CompareType.find((item) => item.value === compare_type).name} Comparison)</td>
              {tableComparePriceData.map(data => (
                <td key={data.year + '-' + data.month}>{data.average_useable_price.toFixed(2)}</td>
              ))}
            </tr>)}
          {compare_type !== 0 && (
            <tr>
              <td>Difference</td>
              {tablePriceData.map((data, index) => {
                const compareData = tableComparePriceData[index];
                if (!compareData) return <td key={data.year + '-' + data.month}></td>; // Handle undefined compareData    

                const percentageDifference = calculatePercentageDifference(data.average_useable_price, compareData.average_useable_price).toFixed(2);
                const { color, arrow } = getColorAndArrow(data.average_useable_price, compareData.average_useable_price);

                return (
                  <td key={data.year + '-' + data.month} style={{ color }}>
                    {arrow} {percentageDifference}%
                  </td>
                );
              })}

            </tr>
          )}
        </tbody>
      </table>
      <div style={{ marginTop: '20px', textAlign: 'center' }}>
        <Button onClick={handleBackwardClick} label="Backward" />
        <Button onClick={handleForwardClick} label="Forward" />
      </div>
    </Box>
  )
}

function PaceReport() {
  const [selectedProperties, setSelectedProperties] = useState([]);

  const [selectedPerdiodType, setSelectedPerdiodType] = useState(3);
  const [selectedCompareType, setSelectedCompareType] = useState(0);
  const [overallDifference, setoverallDiffernece] = useState(false);

  return (
    <Box display="flex" flexDirection="row" flex="1" width="100%" height="100%" margin="5px">
      <Box height="100%" width="100%" flex="1" flexDirection="column" display="flex">
        <Box>
          <Typography variant="h6" color={orangeColor}>Sales report</Typography>
        </Box>
        <PropertiesList onSelect={(items) => setSelectedProperties(items)} />
      </Box>
      <Box flex="5" width="100%" height="100%" display="flex" flexDirection="column" >
        <Box justifyContent="start"
          alignItems="center" display="flex"
          flexDirection="row"
          bgcolor={orangeColor} p={1}
          borderRadius={4}>
          <Typography variant="h6" marginRight="16px">Period Type:</Typography>
          <SelectButton value={selectedPerdiodType} onChange={(e) => setSelectedPerdiodType(e.value)} optionLabel="name" options={PeriodType} optionValue="value" />
          <Typography variant="h6" marginRight="16px">Compare Type:</Typography>
          <Dropdown value={selectedCompareType} onChange={(e) => setSelectedCompareType(e.value)} options={CompareType} optionLabel="name" optionValue="value" />
          <Typography variant="h6" marginLeft="5px" marginRight="16px">Calc overall difference</Typography>
          <Checkbox checked={overallDifference} onChange={e => { setoverallDiffernece(e.checked) }} />
        </Box>
        <Box position="relative" width="100%" height="100%">
          <Box position="absolute" overflow="auto" top="0" left="0" right="0" bottom="0" >
            {
              selectedProperties.map((item, index) => {
                return (
                  <PaceItem
                    key={item.property_id}
                    property_id={item.property_id}
                    period_type={selectedPerdiodType}
                    compare_type={selectedCompareType}
                    property_name={item.name} calcOverllDiff={overallDifference} />)
              })
            }
          </Box>
        </Box>

      </Box >
    </Box >
  )
}

export default PaceReport;
