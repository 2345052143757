import React, { useEffect, useState } from 'react';
import {
  Box,
  TextField,
  Button,
  Typography,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Snackbar, Alert
} from '@mui/material';
import { DialogSaveButton, DialogCancelButton } from '../../../common/DialogButtons';
import { orangeColor, CommonSX } from '../../../css/common_sx';
import axios from 'axios';
export default function CreateNewDeviceFile({ open, onClose, onNew, deviceID }) {
  const [fileName, setFileName] = useState('');

  const handleSave = () => {
    if (!fileName || fileName.length < 3) {
      return;
    }
    axios.post(`/LeviathanMonitoring/Devices/${deviceID}/files`, { file_name: fileName })
      .then((response) => {
        if (response.status === 200) {
          axios.get(`/LeviathanMonitoring/Devices/${deviceID}/files/${response.data.id}`)
            .then((response) => {
              onNew(response.data);
              onClose();
            })
            .catch((err) => { });
        }
      })
      .catch((err) => { });
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth='md'>
      <DialogTitle sx={{ backgroundColor: orangeColor, color: 'white' }}>Add New Device File for {deviceID}</DialogTitle>
      <DialogContent>
        <Box mt={4} mb={1}>
          <TextField label='File Name' onChange={(e) => setFileName(e.target.value)} fullWidth />
        </Box>
      </DialogContent>
      <DialogActions>
        <DialogSaveButton onClick={handleSave} />
        <DialogCancelButton onClick={onClose} />
      </DialogActions>
    </Dialog>
  );
}
