import React, { useEffect, useState } from 'react';
import {
  Box,
  TableRow,
  TableCell,
  TextField,
  IconButton,
  Typography,
  CircularProgress,
  Tooltip,
  Checkbox,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import Autocomplete from '@mui/material/Autocomplete';
import axios from 'axios';
import { TableGrid } from '../../common/TableGrid';
import { orangeColor } from '../../css/common_sx';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Info from '@mui/icons-material/Info';
import CreateNewDeviceFile from './devices/add_new_file';

export default function MonitoringDPI({ visible, device }) {
  const [dpis, setDpis] = useState([]);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editingRow, setEditingRow] = useState(null);
  const [tempValue, setTempValue] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [openCreateNewFile, setOpenCreateNewFile] = useState(false);


  const fetchDpis = async () => {
    try {
      const response = await axios.get(`/LeviathanMonitoring/Devices/${device.id}/dpis`);
      setDpis(response.data);
      const responseFiles = await axios.get(`/LeviathanMonitoring/Devices/${device.id}/files`);
      setFiles(responseFiles.data);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const saveKeyValue = async (id, newValue) => {
    try {
      await axios.put(`/LeviathanMonitoring/Devices/${device.id}/dpis/${id}`, { key_value: newValue });
      setDpis((prev) =>
        prev.map((dpi) => (dpi.id === id ? { ...dpi, key_value: newValue } : dpi))
      );
      setEditingRow(null);
      setTempValue('');
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchDpis();
  }, [visible, device]);

  const columns_dpis = [
    { label: 'ID', searchable: true, onSearch: (value) => console.log(value) },
    , 'DPI Name', 'DPI Value', 'Files', {
      label: 'Actions', actions: [
        { icon: AddIcon, onClick: () => { } }
      ]
    }];
  const columns_files = ['ID', 'File Name', 'Cluster ID', 'Actions'];

  return (
    <Box display="flex" flexDirection="column" flex="1" width="100%" height="100%">
      <Box display="flex" flexDirection="column" width="100%" height="100%" flex={2}>
        <TableGrid columns={columns_dpis} loading={loading} rowsPerPage={10}>
          {dpis.map((dpi) => (
            <TableRow key={dpi.id}>
              <TableCell>{dpi.id}</TableCell>
              <TableCell>{dpi.key_ident}</TableCell>
              <TableCell
                onDoubleClick={() => {
                  setEditingRow(dpi.id);
                  setTempValue(dpi.key_value);
                }}
              >
                {editingRow === dpi.id ? (
                  <Box display="flex" justifyContent='center'>
                    <TextField
                      value={tempValue}
                      onChange={(e) => setTempValue(e.target.value)}
                      size="small"
                      variant="outlined"
                    />
                    <IconButton
                      onClick={() => saveKeyValue(dpi.id, tempValue)}
                      size="small"
                      color="primary"
                    >
                      <SaveIcon />
                    </IconButton>
                  </Box>
                ) : (
                  dpi.key_value
                )}
              </TableCell>
              <TableCell>{dpi?.files?.map((el) => <Typography>{el.file_name}</Typography>)}</TableCell>
              <TableCell>
                <IconButton sx={{ padding: 0 }} onClick={() => { }}><Info /></IconButton>
                {selectedFile !== null ?
                  <Checkbox
                    sx={{ padding: 0 }}
                    checked={
                      dpi.files.find((el) => parseInt(el.file_id) === parseInt(selectedFile.id)) !== undefined
                    }
                  />
                  : null}
              </TableCell>
            </TableRow>
          ))}
        </TableGrid>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        padding={0}
        mb={1}
        mt={1}
        borderRadius={2}
        boxShadow={3}
        bgcolor={orangeColor}
      >
        <Box flex={1}>
          <Typography ml={1} color="white" variant="h4">
            Files {selectedFile !== null ? `Selected File: ${selectedFile.file_name}` : ''}
          </Typography>
        </Box>
        <Box justifyContent="right" display="flex" flex={1}>
          <IconButton onClick={() => {
            setOpenCreateNewFile(true);
          }}>
            <AddIcon />
          </IconButton>
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" width="100%" height="100%" flex={1}>
        <TableGrid columns={columns_files} loading={loading} rowsPerPage={10}>
          {files.map((file) => (
            <TableRow key={file.id} hover >
              <TableCell>{file.id}</TableCell>
              <TableCell>{file.file_name}</TableCell>
              <TableCell>{file.cluster_name}</TableCell>
              <TableCell>
                <Tooltip title="Assign DPI's">
                  <IconButton
                    disabled={
                      selectedFile !== null && selectedFile.id !== file.id
                    }
                    sx={{ padding: 0 }}
                    onClick={() => {
                      setSelectedFile(file);
                    }}
                  ><ArrowForwardIcon />
                  </IconButton>
                </Tooltip>
                {selectedFile !== null && selectedFile.id === file.id ? (
                  <Tooltip title="Save">
                    <IconButton
                      sx={{ padding: 0 }}
                      onClick={() => {
                        setSelectedFile(null);
                      }}
                    ><SaveIcon />
                    </IconButton>
                  </Tooltip>
                ) : null}
              </TableCell>
            </TableRow>
          ))}
        </TableGrid>
      </Box>
      <CreateNewDeviceFile
        open={openCreateNewFile}
        onClose={() => setOpenCreateNewFile(false)}
        deviceID={device.id}
        onNew={(item) => {
          setFiles([item, ...files]);
        }}
      />
    </Box>
  );
}
